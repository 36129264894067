import _ from 'lodash'

import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Button,
  TextField,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableBody,
  Stack,
  Typography,
  Paper,
  Pagination,
  IconButton,
} from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'

import { Master } from '../../../api/types'
import useSearch from '../../hooks/useSearch'
import { Filter, MasterFieldConfig, MasterFilterConfig } from '../types'
import useAuthStore from '../../../store/authStore'

export interface MasterListProps {
  resource: string
  fields: MasterFieldConfig[]
  filters: MasterFilterConfig[]
}

export default function MasterListCommon({
  resource,
  fields,
  filters,
}: MasterListProps) {
  const { t } = useTranslation()

  const { superUser, referenceUser } = useAuthStore()
  const {
    query: { data },
    form: { register },
    handleSubmit,
    page,
    handlePageChange,
  } = useSearch<Filter, Master>(`effective-${resource}`)

  const navigate = useNavigate()
  if (!(superUser || !referenceUser)) {
    return <Navigate to="/" />
  }

  return (
    <Stack spacing={2}>
      <Typography>
        {t(`masters.${resource}.name`) + t('masters.common.title.list')}
      </Typography>
      <Stack direction="row-reverse">
        <Button
          startIcon={<AddIcon />}
          color="primary"
          variant="contained"
          onClick={() => navigate('new')}
        >
          {t('actions.new')}
        </Button>
      </Stack>
      <Paper>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} p={2}>
            <TextField
              key="code"
              label={t(`masters.common.fields.code`)}
              variant="outlined"
              size="small"
              fullWidth
              {...register('code', { required: false })}
            />
            {filters.map(({ name }) => (
              <TextField
                key={name}
                label={t(`masters.${resource}.fields.${name}`)}
                variant="outlined"
                size="small"
                fullWidth
                {...register(name, { required: false })}
              />
            ))}
            <Stack direction="row-reverse">
              <Button
                type="submit"
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
              >
                <Typography noWrap>{t('actions.search')}</Typography>
              </Button>
            </Stack>
          </Stack>
        </form>
      </Paper>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: `${_.ceil(100 / (2 + fields.length), 0)}%`,
                    textAlign: 'left',
                  }}
                  colSpan={2}
                >
                  {t(`masters.common.fields.code`)}
                </TableCell>
                {fields.map(({ name }) => (
                  <TableCell
                    key={name}
                    sx={{
                      width: `${_.ceil(100 / (2 + fields.length), 0)}%`,
                      textAlign: 'left',
                    }}
                  >
                    {t(`masters.${resource}.fields.${name}`)}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    width: `${_.ceil(100 / (2 + fields.length), 0)}%`,
                    textAlign: 'left',
                  }}
                >
                  {t(`masters.common.fields.apply_date`)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                // change background color of odd table rows
                '& tr:nth-of-type(odd)': {
                  bgcolor: 'action.hover',
                },
              }}
            >
              {data?.results?.map((item) => (
                <TableRow key={item.code}>
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        navigate(`${item.code}`)
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  {fields.map(({ name }) => (
                    <TableCell key={name} align="left">
                      {item[name]}
                    </TableCell>
                  ))}
                  <TableCell align="left">{item.apply_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Stack direction="row" justifyContent="center">
        <Pagination
          count={data ? _.ceil(data.count / data.results.length) : 0}
          page={Number(page)}
          onChange={handlePageChange}
        />
      </Stack>
    </Stack>
  )
}
