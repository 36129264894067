import dayjs from 'dayjs'

import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, Card, Stack, TextField, Typography } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import MultiAddIcon from '@mui/icons-material/PlaylistAdd'

import { useAPI } from '../../../api'
import { MasterFieldConfig } from '../types'
import { Master, MasterCreatePayload } from '../../../api/types'
import useAuthStore from '../../../store/authStore'

export interface MasterNewProps {
  resource: string
  fields: MasterFieldConfig[]
}

export default function MasterNewCommon({ resource, fields }: MasterNewProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const api = useAPI()

  const { superUser, referenceUser } = useAuthStore()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<MasterCreatePayload>({
    defaultValues: {
      apply_date: dayjs().format('YYYY-MM-DD'),
    },
  })

  const createAndRedirectMutation = useMutation({
    mutationFn: (data: MasterCreatePayload) =>
      api.create<Master>(resource, data),
    onSuccess: ({ code }) => {
      navigate(`../${code}`, { replace: true, relative: 'path' })
    },
  })

  const createAndResetMutation = useMutation({
    mutationFn: (data: MasterCreatePayload) =>
      api.create<Master>(resource, data),
    onSuccess: () => {
      // reset form
      reset()
    },
  })
  if (!(superUser || !referenceUser)) {
    return <Navigate to="/" />
  }

  return (
    <form>
      <Stack spacing={2}>
        <Typography>
          {t(`masters.${resource}.name`) + t('masters.common.title.detail')}
        </Typography>
        <Stack direction="row-reverse" spacing={2}>
          <Button
            name="createAndReset"
            color="primary"
            variant="contained"
            type="submit"
            disabled={
              createAndResetMutation.isPending ||
              createAndRedirectMutation.isPending
            }
            onClick={handleSubmit((data) =>
              createAndResetMutation.mutate(data)
            )}
            startIcon={<MultiAddIcon />}
          >
            <Typography noWrap>{t('actions.repeatRegister')}</Typography>
          </Button>
          <Button
            name="createAndRedirect"
            color="primary"
            variant="contained"
            type="submit"
            disabled={
              createAndResetMutation.isPending ||
              createAndRedirectMutation.isPending
            }
            onClick={handleSubmit((data) => {
              createAndRedirectMutation.mutate(data)
            })}
            startIcon={<AddIcon />}
          >
            <Typography noWrap>{t('actions.create')}</Typography>
          </Button>
        </Stack>
        <Card>
          <Stack spacing={2} p={2}>
            <TextField
              label={t('masters.common.fields.code')}
              variant="outlined"
              size="small"
              fullWidth
              error={Boolean(errors?.code)}
              helperText={errors?.code?.message}
              {...register('code', {
                required: t('validations.required', {
                  field: t('masters.common.fields.code'),
                }),
              })}
            />
            {fields.map(({ name }) => (
              <TextField
                key={name}
                label={t(`masters.${resource}.fields.${name}`)}
                variant="outlined"
                size="small"
                fullWidth
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                {...register(name, {
                  required: t('validations.required', {
                    field: t(`masters.${resource}.fields.${name}`),
                  }),
                })}
              />
            ))}
            <TextField
              label={t('masters.common.fields.apply_date')}
              type="date"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors?.apply_date)}
              helperText={errors?.apply_date?.message}
              {...register('apply_date', {
                required: t('validations.required', {
                  field: t('masters.common.fields.apply_date'),
                }),
              })}
            />
          </Stack>
        </Card>
      </Stack>
    </form>
  )
}
