import { createTheme } from '@mui/material'

let theme = createTheme()
theme = createTheme(theme, {
  palette: {
    info: theme.palette.augmentColor({
      color: {
        //cyan
        main: '#00bcd4',
        contrastText: '#fff',
      },
      name: 'info',
    }),
  },
  // override autocomplete styles
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingLeft: '14px',
        },
      },
    },
  },
})
export default theme
