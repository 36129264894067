import {
  Autocomplete,
  IconButton,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { useAPI } from '../../../api'
import { useTranslation } from 'react-i18next'
import usePrintStore from '../../../store/printStore'
import fetch from '../../../api/axios'
import DeleteIcon from '@mui/icons-material/Delete'
import { AxiosResponse } from 'axios'
import { Filter } from '../../Invoice/types'

interface PrintDrafts {
  id: number
  name: string
}

interface TemplateListSearchProps {
  filter?: Filter | null
  onChangeIndicator: (isValueSelected: boolean, templateName: string) => void
}

export function TemlateListSearch({
  filter = undefined,
  onChangeIndicator,
}: TemplateListSearchProps) {
  const { t } = useTranslation()
  const api = useAPI()
  const resource = 'print-template'
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState<readonly PrintDrafts[]>([])
  const updateProductList = usePrintStore((state: any) => state.setProducts)

  const loading = open && options.length === 0
  React.useEffect(() => {
    let active = true
    if (!loading) {
      return undefined
    }
    ;(async () => {
      const resource = 'print-template'
      let results: AxiosResponse<any, any>
      if (!filter) {
        results = await fetch.get('print-template')
      } else {
        const action = 'filtered-templates'
        results = await fetch.post(`${resource}/${action}/`, {
          filter: filter,
        })
      }
      if (active) {
        setOptions([...results.data])
      }
    })()
    return () => {
      active = false
    }
  }, [loading, filter])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  const deleteTemplateMutation = useMutation({
    mutationFn: (id: number) => api.delete(resource, id),
    onSuccess: () => {},
  })
  // to prevent the simultanous trigger of onChange and Delete function
  let isDeleting = false
  const handleTemplateSearchChange = async (data: PrintDrafts) => {
    if (!isDeleting) {
      const action = 'details'

      const get_linked_products = await fetch.get(
        `${resource}/${data.id}/${action}`
      )
      updateProductList(get_linked_products.data)
    }
  }
  const handleDeleteTemplate = (option: PrintDrafts) => {
    isDeleting = true
    deleteTemplateMutation.mutate(option.id)
  }
  return (
    <>
      {' '}
      <Autocomplete
        id="asynchronous-demo"
        disabled={deleteTemplateMutation.isPending}
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        noOptionsText={t('printLists.noDataMessage')}
        size="small"
        // loading={loading}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={t('printLists.printTemplateList')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {/* {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null} */}
                    {null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              InputLabelProps={{
                children: <span>{}</span>,
              }}
            />
          </>
        )}
        onChange={(event, value: PrintDrafts | null, reason) => {
          if (value) {
            // Call the handleNameSelect function when the name is selected
            onChangeIndicator(true, value.name)
            handleTemplateSearchChange(value)
          }
          if (reason === 'clear') {
            // if the select box is cleared.
            onChangeIndicator(false, '')
          }
        }}
        renderOption={(props, option) => (
          <ListItem {...props}>
            <ListItemText primary={option.name} />
            <IconButton
              edge="end"
              onClick={() => handleDeleteTemplate(option)}
              size="small"
            >
              {!filter && <DeleteIcon />}
            </IconButton>
          </ListItem>
        )}
      />
    </>
  )
}
