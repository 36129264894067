import { createContext, useContext } from 'react'
import { Product } from '../../../api/types'

const InitialDataContext = createContext<Product | undefined>(undefined)

export const InitialDataProvider = InitialDataContext.Provider

export const useInitalData = () => {
  return useContext(InitialDataContext)
}
