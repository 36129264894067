import { ceil, isEmpty, mapKeys, union } from 'lodash'

import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'

import * as yup from 'yup'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
} from '@mui/material'
import {
  MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'

import SearchIcon from '@mui/icons-material/Search'
import TaskIcon from '@mui/icons-material/Task'

import {
  BatchUpdateAction,
  BatchUpdateFilter,
  BatchUpdateInput,
  BatchUpdateResult,
} from './types'
import { Product } from '../../api/types'

import useSearch from '../hooks/useSearch'
import { useAPI } from '../../api'

import SearchInput from './SearchInput'
import UpdateInput from './UpdateInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { t } from 'i18next'

type SearchResult = Partial<Product>

const FIXED_COLUMNS = ['product_name', 'country_of_origin', 'hs_code']

const searchSchema = yup.object({
  filter1: yup.string().when(['filter2', 'filter3'], {
    is: (filter2: string, filter3: string) => !filter2 && !filter3,
    then: () =>
      yup.string().required(
        t('validations.atLeastOne', {
          field: t('batch.filters.filter'),
        })
      ),
  }),
  from1: yup.string().when('filter1', {
    is: (filter1: string, choice1: string) =>
      filter1 && filter1 !== 'certificate_status',
    then: () =>
      yup.string().required(
        t('validations.required', {
          field: t('batch.filters.from'),
        })
      ),
  }),
  from2: yup.string().when('filter2', {
    is: (filter2: string, choice2: string) =>
      filter2 && filter2 !== 'certificate_status',
    then: () =>
      yup.string().required(
        t('validations.required', {
          field: t('batch.filters.from'),
        })
      ),
  }),
  from3: yup.string().when('filter3', {
    is: (filter3: string, choice3: string) =>
      filter3 && filter3 !== 'certificate_status',
    then: () =>
      yup.string().required(
        t('validations.required', {
          field: t('batch.filters.from'),
        })
      ),
  }),
})

const updateSchema = yup.object({
  field: yup.string().required(),
  from_value: yup.string(),
  to_value: yup.string(),
})

export default function BatchUpdate() {
  /* UI/UX */
  const api = useAPI()
  const { t } = useTranslation()
  const navigate = useNavigate()

  /* state */
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showResult, setShowResult] = useState<boolean>(false)

  //   /* query */
  const {
    query: { data, isFetching, refetch },
    form: searchForm,
    handleSubmit,
    page,
    handlePageChange,
    filterValues,
  } = useSearch<BatchUpdateFilter, SearchResult>(
    'batch',
    {
      filter1: '',
      filter2: '',
      filter3: '',
      from1: '',
      from2: '',
      from3: '',
      to1: '',
      to2: '',
      to3: '',
      condition: 'AND',
      page_size: 30,
    },
    yupResolver(searchSchema)
  )

  const updateForm = useForm<BatchUpdateInput>({
    defaultValues: {
      field: '',
      from_value: '',
      to_value: '',
      all: false,
    },
    resolver: yupResolver(updateSchema) as any,
  })
  const handleUpdate = updateForm.handleSubmit(() => {
    setShowConfirm(true)
  })

  const updateMutation = useMutation<BatchUpdateResult>({
    mutationFn: () => {
      const { all, ...payload } = updateForm.getValues()
      // convert rowSelection to id array

      return api.updateMany(
        'batch',
        {
          ...searchForm.getValues(),
          id: all ? ['*'] : Object.keys(table.getState().rowSelection),
        },
        payload
      )
    },
    onSettled: () => {
      setShowResult(true)
    },
  })

  /* render */
  const columns = useMemo<MRT_ColumnDef<SearchResult>[]>(() => {
    const { filter1, filter2, filter3 } = filterValues as BatchUpdateFilter
    const fields = [filter1, filter2, filter3].filter((f) => !isEmpty(f))
    // merge fixed columns and selected columns without duplication
    const allFields = union(fields, FIXED_COLUMNS)
    return allFields.map((field) => ({
      accessorKey: field,
      header: t(`products.fields.${field!.split('__')[0]}`),
    }))
  }, [filterValues, t])

  const table = useMaterialReactTable({
    columns,
    data: data?.results || [],
    state: {
      isLoading: isFetching,
      columnOrder: [
        'mrt-row-select',
        ...columns.map((c) => c.accessorKey),
      ] as string[],
    },
    enableSorting: false,
    enableColumnResizing: true,
    enableColumnActions: false,
    initialState: {
      density: 'compact',
    },
    enablePagination: false,
    enableBottomToolbar: false,
    positionToolbarAlertBanner: 'none',
    enableTopToolbar: false,
    enableClickToCopy: true,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString() ?? '',
    localization: {
      noRecordsToDisplay: undefined,
    },
    enableTableFooter: false,
  })

  return (
    <>
      <Stack spacing={2}>
        <Card sx={{ p: 2 }}>
          <CardHeader title={t('actions.search')} />
          <CardContent>
            <FormProvider {...searchForm}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={10}>
                    <SearchInput group={1} />
                  </Grid>
                  <Grid item xs={1}>
                    <Controller
                      name="condition"
                      render={({ field }) => (
                        <Select {...field} size="small" fullWidth>
                          <MenuItem value="AND">
                            {t('preferences.conditions.and')}
                          </MenuItem>
                          <MenuItem value="OR">
                            {t('preferences.conditions.or')}
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <SearchInput group={2} />
                  </Grid>
                  <Grid item xs={1}>
                    <Controller
                      name="page_size"
                      render={({ field }) => (
                        <Select {...field} size="small" fullWidth>
                          <MenuItem value={30}>
                            {t('preferences.pageSize.30')}
                          </MenuItem>
                          <MenuItem value={50}>
                            {t('preferences.pageSize.50')}
                          </MenuItem>
                          <MenuItem value={100}>
                            {t('preferences.pageSize.100')}
                          </MenuItem>
                        </Select>
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={10}>
                    <SearchInput group={3} />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      startIcon={<SearchIcon />}
                    >
                      {t('actions.search')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </CardContent>
        </Card>
        <Card sx={{ p: 2 }}>
          <CardHeader title={t('actions.update')} />
          <CardContent>
            <FormProvider {...updateForm}>
              <form onSubmit={handleUpdate}>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={10}>
                    <UpdateInput />
                  </Grid>
                  <Grid item xs={1} display="flex" justifyContent="center">
                    <Controller
                      name="all"
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Checkbox {...field} />}
                          label={t('batch.fields.all')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      startIcon={<TaskIcon />}
                    >
                      {t('actions.update')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={showConfirm}
                  onClose={() => {
                    setShowConfirm(false)
                  }}
                >
                  <DialogTitle>{t('actions.confirm')}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('messages.batchConfirm')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setShowConfirm(false)
                        updateMutation.mutate()
                      }}
                      variant="contained"
                      color="primary"
                    >
                      {t('actions.ok')}
                    </Button>
                    <Button
                      onClick={() => {
                        setShowConfirm(false)
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      {t('actions.cancel')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            </FormProvider>
          </CardContent>
        </Card>
        <MaterialReactTable table={table} />
        <Stack direction="row" justifyContent="center">
          <Pagination
            count={data ? ceil(data.count / filterValues.page_size) : 0}
            page={Number(page)}
            onChange={handlePageChange}
          />
        </Stack>
      </Stack>
      <Dialog open={showResult} onClose={() => setShowResult(false)}>
        <DialogTitle>{t('actions.confirm')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {updateMutation.isSuccess
              ? t('messages.batchFinished', {
                  count: updateMutation.data?.count,
                })
              : t('messages.batchFailed')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowResult(false)
              updateForm.reset()
              refetch()
            }}
          >
            {t('actions.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
