import { useParams } from 'react-router-dom'
import { MasterConfig, MasterFieldConfig, MasterFilterConfig } from './types'
const masters: {
  [resource: string]: {
    fields: MasterFieldConfig[]
    filters: MasterFilterConfig[]
  }
} = {
  consignors: {
    fields: [{ name: 'japanese_name' }, { name: 'english_name' }],
    filters: [{ name: 'japanese_name' }, { name: 'english_name' }],
  },
  shippers: {
    fields: [{ name: 'name' }],
    filters: [{ name: 'name' }],
  },
  countries: {
    fields: [{ name: 'name' }],
    filters: [{ name: 'name' }],
  },
  regulations: {
    fields: [{ name: 'name' }],
    filters: [{ name: 'name' }],
  },
}

export function useMasterConfig(): MasterConfig | null {
  const { resource } = useParams()
  if (!resource || !masters[resource]) return null
  const { fields, filters } = masters[resource]
  return { resource, fields, filters }
}
