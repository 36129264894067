import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

interface MessageDialogProps {
  message: string
  openDialog: boolean
  onDialogClose: () => void
}

export default function MessageDialog({
  message,
  openDialog,
  onDialogClose,
}: MessageDialogProps) {
  const { t } = useTranslation()
  return (
    <Dialog open={openDialog} onClose={onDialogClose}>
      <DialogTitle>{t('actions.confirm')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose}>{t('actions.ok')}</Button>
      </DialogActions>
    </Dialog>
  )
}
