import React, { useMemo, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Link, useNavigate } from 'react-router-dom'
import ListAltIcon from '@mui/icons-material/ListAlt'
import {
  Button,
  Stack,
  Badge,
  ButtonGroup,
  lighten,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Box,
  Alert,
  Modal,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { ListQueryResult, Master } from '../../../api/types'
import usePrintStore from '../../../store/printStore'
import { debounce } from 'lodash'
import { Print, Save } from '@mui/icons-material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import useDrawerStore from '../../../store/drawerStore'
import FilePresentIcon from '@mui/icons-material/FilePresent' // styles
import { useAPI } from '../../../api'
import { TemlateListSearch } from './TemplateListSearch'
const headerCellStyle = {
  border: '1px solid #ddd',
  padding: '2px',
  whiteSpace: 'initial',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold', // Add bold font style for header cells
  fontSize: '12px',
  '@media print': {
    fontSize: '2px !important',
    height: '7px !important',
  },
}

const cellStyle = {
  border: '1px solid #ddd',
  padding: '1px',
  whiteSpace: 'initial',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  '@media print': {
    fontSize: '7px !important',
  },
}

const colStyles = [
  { width: '12%' },
  { width: '12%' },
  { width: '16%' },
  { width: '14%' },
  { width: '15%' },
  { width: '6%' },
  { width: '7%' },
  { width: '3%' },
  { width: '4%' },
  { width: '3%' },
]

const noPrint = {
  '@media print': {
    display: 'none',
  },
}
const clearIconStyle = {
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
}

const clearIconCellStyle = { ...cellStyle, textAlign: 'center', ...noPrint }
const clearIconHeaderStyle = { ...headerCellStyle, width: '3%', ...noPrint }

// props defination
interface DraggableTableRowProps {
  id: number | undefined
  index: number
  indexOfProductName: number
  indexOfHasImage: number
  moveRow: (draggedIndex: number, targetIndex: number) => void
  data: any[]
  onDeleteRow: (id: number) => void
  onProductNameClick: () => void
  sortMode: boolean
}

const DraggableTableRow: React.FC<DraggableTableRowProps> = ({
  id,
  index,
  indexOfProductName,
  indexOfHasImage,
  moveRow,
  data,
  onDeleteRow,
  onProductNameClick,
  sortMode,
}) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: 'ROW',
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, dropRef] = useDrop({
    accept: 'ROW',
    hover: (draggedItem: { index: number }, monitor) => {
      if (draggedItem.index !== index) {
        moveRow(draggedItem.index, index)
        draggedItem.index = index
      }
    },
  })
  const ref = (node: HTMLDivElement | null) => {
    if (sortMode) {
      dragRef(dropRef(node))
    }
  }
  return (
    <TableRow
      sx={{
        '&:nth-of-type(odd)': {
          backgroundColor: lighten('rgba(220,220,220, .5)', 0.1),
        },
        opacity: isDragging ? 1 : 1, // More opaque when dragging
        boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Box shadow when dragging
        transition: 'all 0.2s ease', // Smooth transition for all properties
      }}
      ref={ref}
    >
      <TableCell sx={clearIconCellStyle}>
        {' '}
        {/* table cell for clear icon */}
        <ClearIcon
          color="primary"
          sx={clearIconStyle}
          onClick={() => onDeleteRow(index)}
        />
      </TableCell>
      {data.map((column, colIndex) => (
        <TableCell
          key={colIndex}
          sx={{
            ...cellStyle,
            ...colStyles[colIndex],
            '@media print': {
              display: colIndex === indexOfHasImage ? 'none' : '',
            },
          }}
        >
          {colIndex === indexOfProductName ? (
            <Link to={`/print?id=${id}`} style={{ textDecoration: 'none' }}>
              {column}
            </Link>
          ) : colIndex === indexOfHasImage ? (
            <>{column === true ? <FilePresentIcon fontSize="small" /> : ''}</>
          ) : (
            column
          )}
        </TableCell>
      ))}
      {/* <TableCell sx={dragIconCellStyle}>
        {' '}
        table cell for drag icon
        <div ref={(node) => dragRef(dropRef(node))}>
          <DragHandleIcon sx={dragIconStyle} />
        </div>
      </TableCell> */}
    </TableRow>
  )
}

export default function PrintList() {
  const { t } = useTranslation()
  const api = useAPI()
  const resource = 'print-template'
  const navigate = useNavigate()
  const { closeDrawer } = useDrawerStore()
  const [templateName, setTemplateName] = useState('')
  const [nullNameError, setNullNameError] = useState(true)
  const [openModal, setOpenModal] = React.useState(false)
  const [sortMode, setSortMode] = React.useState(false)
  const handleModalOpen = () => {
    setOpenModal(true)
    setNullNameError(false)
  }
  const handleModalClose = () => setOpenModal(false)
  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
  }

  const [
    printCount,
    printIds,
    removeParticularProduct,
    clearPrintProduct,
    reOrderProducts,
  ] = usePrintStore(
    useShallow((state) => [
      state.products.length,
      state.products,
      state.removeParticularProduct,
      state.clearProducts,
      state.changeProductOrdering,
    ])
  )

  const { data, isLoading, isError } = useQuery<ListQueryResult<Master>>({
    queryKey: ['getPrintList', 'print', printIds],
  })

  const sortedData = useMemo(() => {
    if (!data || !data.results) {
      return []
    }
    const dataMap = new Map(data.results.map((item, index) => [item.id, item]))
    const sortedData = printIds.map((id) => dataMap.get(id)).filter(Boolean)
    return sortedData
  }, [data, printIds])

  const bulkPrint = () => {
    if (printCount > 0) {
      const ids = printIds.join('&id=')
      const url = `/print?id=${ids}`
      navigate(url)
    }
  }
  const createTemplateMutation = useMutation({
    mutationFn: () =>
      api.create(resource, { name: templateName, product_ids: printIds }),
    onSuccess: () => {
      setOpenModal(false)
      setTemplateName('')
    },
  })

  const saveTemplate = async () => {
    if (templateName === '') {
      setNullNameError(true)
      // alert('setTemplateName')
    } else {
      setNullNameError(false)
      if (printCount > 0) {
        // api.create(resource, { name: templateName, product_ids: printIds })
        createTemplateMutation.mutate()
      }
    }
  }

  //ToDO: Delay the printFunction so that Drawer is closed first before printing
  const printDebounced = debounce(() => {
    window.print()
  }, 100)

  const printScreen = () => {
    closeDrawer()
    printDebounced()
  }

  const columns = [
    'jan_code',
    'product_no',
    'product_name',
    'material',
    'usage',
    'country_of_origin',
    'hs_code',
    'tariff_rate',
    'certificate_status_indication',
    'has_image',
  ]

  const translatedHeaders = [
    t('products.fields.jan_code'),
    t('products.fields.product_no'),
    t('products.fields.product_name'),
    t('products.fields.material'),
    t('products.fields.usage'),
    t('products.fields.country_of_origin'),
    t('products.fields.hs_code'),
    t('products.fields.tariff_rate'),
    t('products.fields.certificate_of_origin_short'),
    t('products.fields.imageIndicator'),
  ]

  const handleMoveRow = (draggedIndex: number, targetIndex: number) => {
    const reorderedIds = [...printIds] // Assuming printIds is an array of ids
    const [draggedItem] = reorderedIds.splice(draggedIndex, 1)
    reorderedIds.splice(targetIndex, 0, draggedItem)
    reOrderProducts(reorderedIds)
  }

  return (
    <Stack spacing={2} sx={{ px: 0, py: 0 }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={noPrint}
        alignItems={'center'}
      >
        <Button
          startIcon={<DeleteIcon />}
          color="primary"
          variant="contained"
          onClick={() => {
            clearPrintProduct()
          }}
        >
          {t('actions.clear')}
        </Button>
        <TemlateListSearch onChangeIndicator={() => {}} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    setSortMode(e.target.checked)
                  }}
                />
              }
              label={t('actions.sort')}
            />
          </FormGroup>
          <ButtonGroup variant="contained">
            <Button onClick={handleModalOpen}>
              {t('actions.saveTemplate')}
            </Button>
            <Modal
              open={openModal}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={9}>
                    <TextField
                      id="template-name"
                      label={t('printLists.templateName')}
                      variant="outlined"
                      value={templateName}
                      inputProps={{ maxLength: 100 }}
                      fullWidth
                      onChange={(e) => {
                        setTemplateName(e.target.value)
                        setNullNameError(false)
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      disabled={createTemplateMutation.isPending}
                      startIcon={<Save />}
                      onClick={saveTemplate}
                      fullWidth
                      size="large"
                    ></Button>
                  </Grid>
                </Grid>
                {nullNameError && (
                  <Grid sx={{ mt: 2 }}>
                    <Alert variant="outlined" severity="error">
                      {t('printLists.nullNameMessage')}
                    </Alert>
                  </Grid>
                )}
              </Box>
            </Modal>
          </ButtonGroup>
          <ButtonGroup variant="contained">
            <Button startIcon={<ListAltIcon />} onClick={printScreen}>
              {t('actions.listPrint')}
            </Button>
          </ButtonGroup>
          <ButtonGroup variant="contained">
            <Badge badgeContent={printCount} color="secondary">
              <Button startIcon={<Print />} onClick={bulkPrint}>
                {t('actions.batchPrint')}
              </Button>
            </Badge>
          </ButtonGroup>
        </Stack>
      </Stack>
      <DndProvider backend={HTML5Backend}>
        <TableContainer>
          <Table style={{ tableLayout: 'fixed', width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={clearIconHeaderStyle}></TableCell>{' '}
                {/* table cell for clear icon */}
                {columns.map((column, index) => (
                  <TableCell
                    key={column}
                    sx={{
                      ...headerCellStyle,
                      ...colStyles[index],
                      '@media print': {
                        display: column === 'has_image' ? 'none' : '',
                      },
                    }}
                  >
                    {translatedHeaders[index]}
                  </TableCell>
                ))}
                {/* <TableCell sx={dragIconHeaderStyle}>並替</TableCell>{' '} */}
                {/* table cell for drag icon */}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, rowIndex) => (
                <DraggableTableRow
                  key={rowIndex}
                  id={row?.id}
                  index={rowIndex}
                  moveRow={handleMoveRow}
                  data={columns.map((column) => (row ? row[column] : ['']))}
                  onDeleteRow={removeParticularProduct}
                  onProductNameClick={bulkPrint}
                  indexOfProductName={columns.indexOf('product_name')}
                  indexOfHasImage={columns.indexOf('has_image')}
                  sortMode={sortMode}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DndProvider>
    </Stack>
  )
}
