import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type PrintStore = {
  products: number[]
  addProduct: (id: number) => void
  removeParticularProduct: (id: number) => void
  clearProducts: () => void
  changeProductOrdering: (ids: number[]) => void
  setProducts: (products: number[]) => void
}

const usePrintStore = create<PrintStore>()(
  devtools(
    persist(
      (set) => ({
        products: [],
        // addProject: add id to products array if not duplicate
        //update on April 08, 2024: add id even if its duplicate
        addProduct: (id) =>
          set((state) => {
            return { products: [...state.products, id] }
          }),
        removeParticularProduct: (currentIndex) =>
          set((state) => ({
            products: state.products.filter(
              (productId, index) => index !== currentIndex
            ),
          })),
        clearProducts: () => set({ products: [] }),
        changeProductOrdering: (ids) => set({ products: ids }),
        setProducts: (products) =>
          set((state) => ({
            products,
          })),
      }),
      { name: 'print-storage' }
    )
  )
)

export default usePrintStore
