import MasterAppendCommon from './common/MasterAppendCommon'
import { useMasterConfig } from './useMasterConfig'

export default function MasterAppend() {
  const config = useMasterConfig()
  if (!config) {
    return null
  }
  const { resource, fields } = config
  return <MasterAppendCommon resource={resource} fields={fields} />
}
