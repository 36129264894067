import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { ExpandableTextInputProps, InputProps } from '../types'

export default function ExpandableTextInput({
  label,
  name,
  printName,
  check = true,
  expandFields,
}: ExpandableTextInputProps) {
  const { control } = useFormContext()
  const [expand, setExpand] = useState(false)

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <TextField
            label={label}
            fullWidth
            {...field}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            InputProps={{
              startAdornment:
                check === null ? undefined : (
                  <InputAdornment position="start">
                    <Controller
                      control={control}
                      disabled={!check}
                      name={printName ?? `print_${name}`}
                      render={({ field: { value, ...fieldProps } }) => (
                        <Checkbox {...fieldProps} checked={value} />
                      )}
                    />
                  </InputAdornment>
                ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setExpand(!expand)}>
                    {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      <Collapse in={expand}>
        <Stack spacing={2} mt={2}>
          {expandFields.map(({ name, label, printName }: InputProps) => (
            <Controller
              control={control}
              key={name}
              name={name}
              render={({ field }) => (
                <TextField
                  label={label}
                  fullWidth
                  {...field}
                  InputProps={{
                    startAdornment:
                      check === null ? undefined : (
                        <InputAdornment position="start">
                          <Controller
                            control={control}
                            disabled={!check}
                            name={printName ?? `print_${field.name}`}
                            render={({ field: { value, ...fieldProps } }) => (
                              <Checkbox {...fieldProps} checked={value} />
                            )}
                          />
                        </InputAdornment>
                      ),
                  }}
                />
              )}
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  )
}
