import { ceil, isEmpty } from 'lodash'
import React, { useMemo, useRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { Controller } from 'react-hook-form'
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonGroup,
  Grid,
  TextField,
  FormHelperText,
  Select,
  Stack,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Badge,
  SelectChangeEvent,
  IconButton,
  Tooltip,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
} from '@mui/material'
import PopupState, { bindPopper, bindTrigger } from 'material-ui-popup-state'
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'

// Icons
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import PrintIcon from '@mui/icons-material/Print'
import ListAltIcon from '@mui/icons-material/ListAlt'
import InputIcon from '@mui/icons-material/Input'
import OutputIcon from '@mui/icons-material/Output'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import SearchIcon from '@mui/icons-material/Search'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AddchartIcon from '@mui/icons-material/Addchart'

import { SearchResult } from '../../../api/types'
import { CODEC_OPTIONS } from '../../../constants'
import usePrintStore from '../../../store/printStore'
import useSearch from '../../hooks/useSearch'
import useAuthStore from '../../../store/authStore'
import { useMutation } from '@tanstack/react-query'
import fetch from '../../../api/axios'
import { saveAs } from 'file-saver'
import MessageDialog from '../../reusableComponents/DialogBox/MessageDIalog'

interface SearchFilter {
  product_name?: string
  country_of_origin?: string
  hs_code?: string
  consignor?: string
  shipper?: string
  product_no?: string
  keyword?: string
  page_size?: number
}

export default function Search() {
  /* tools */
  const { t } = useTranslation()
  const navigate = useNavigate()

  /* states */
  const [printCount, addPrintProduct, clearPrintProduct] = usePrintStore(
    useShallow((state) => [
      state.products.length,
      state.addProduct,
      state.clearProducts,
    ])
  )
  const { superUser, referenceUser } = useAuthStore()
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [deleteComplete, setDeleteComplete] = useState(false)
  const [selectItemFirstDialog, setSelectItemFirstDialog] = useState(false)
  const [codec, setCodec] = useState<string>(CODEC_OPTIONS[0])
  const csvFileInputRef = useRef<HTMLInputElement>(null)
  const [importResponseMessage, setImportResponseMessage] = useState('')

  /* handlers */
  const handleSelectCodec = (event: SelectChangeEvent) => {
    setCodec(event.target.value)
  }

  /* query */
  const {
    query: { data, isFetching, refetch },
    form: { register, control },
    handleSubmit,
    page,
    handlePageChange,
  } = useSearch<SearchFilter, SearchResult>('search')
  const [searchParams, setSearchParams] = useSearchParams()
  const page_size_param = searchParams.get('page_size')

  /* render */
  const columns = useMemo<MRT_ColumnDef<SearchResult>[]>(
    () => [
      {
        accessorKey: 'product_name',
        header: t('products.fields.product_name'),
        size: 300,
        enableClickToCopy: false,
        Cell: ({ renderedCellValue, row: { id } }) => {
          return <Link to={`/print?id=${id}`}>{renderedCellValue}</Link>
        },
      },
      {
        accessorKey: 'country_of_origin',
        header: t('products.fields.country_of_origin'),
      },
      {
        accessorKey: 'hs_code',
        header: t('products.fields.hs_code'),
      },
      {
        accessorKey: 'consignor',
        header: t('products.fields.consignor__japanese_name'),
      },
      {
        accessorKey: 'shipper',
        header: t('products.fields.shipper'),
      },
      {
        accessorKey: 'product_no',
        header: t('products.fields.product_no'),
      },
    ],
    [t]
  )
  const table = useMaterialReactTable({
    columns,
    data: data?.results || [],
    state: { isLoading: isFetching },
    enableSorting: false,
    enableColumnResizing: true,
    enableColumnActions: false,
    layoutMode: 'grid-no-grow',
    initialState: {
      columnOrder: [
        'mrt-row-select',
        'mrt-row-actions',
        'product_name',
        'country_of_origin',
        'hs_code',
        'consignor',
        'shipper',
        'product_no',
      ],
      density: 'compact',
    },
    enablePagination: false,
    enableRowSelection: true,
    enableRowActions: true,
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 40,
      },
      'mrt-row-actions': {
        header: t('preferences.table.columns.action'),
        size: 140,
      },
    },
    positionToolbarAlertBanner: 'none',
    enableTopToolbar: false,
    enableClickToCopy: true,
    renderRowActions: ({ row }) => (
      <Stack direction="row" spacing={0} flexShrink={0} key={row.id}>
        {(superUser || !referenceUser) && (
          <>
            <Tooltip title={t('actions.update')} placement="left">
              <IconButton
                onClick={() => navigate(`/update/${row.id}`)}
                color="primary"
              >
                <EditNoteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('actions.copy')} placement="left">
              <IconButton
                onClick={() => navigate(`/copy/${row.id}`)}
                color="primary"
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title={t('actions.add')} placement="left">
          <IconButton
            onClick={() => addPrintProduct(Number(row.id))}
            color="primary"
          >
            <AddchartIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
    getRowId: (row) => row?.id?.toString(),
  })

  const rowSelection = table.getState().rowSelection as MRT_RowSelectionState
  const printSelected = () => {
    if (!isEmpty(rowSelection)) {
      const params = createSearchParams({
        id: Object.keys(rowSelection).reverse(),
      })
      const url = `/print?${params.toString()}`
      navigate(url)
    } else {
      setSelectItemFirstDialog(true)
    }
  }

  const handleCsvExport = async () => {
    if (!isEmpty(rowSelection)) {
      const params = createSearchParams({
        id: Object.keys(rowSelection).reverse(),
      })
      const resource = 'csv'
      try {
        const { data, headers } = await fetch.get(`${resource}?${params}`, {
          paramsSerializer: {
            indexes: null,
          },
          headers: {
            Accept: 'text/csv',
          },
          responseType: 'blob',
        })
        const mineType = headers['content-type']
        const dispostion = headers['content-disposition']
        const fileName = decodeURI(
          dispostion
            .split(';')[1]
            .split('=')[1]
            .replace('"', '')
            .replace('"', '')
        )
        const blob = new Blob([data], { type: mineType })
        saveAs(blob, fileName)
      } catch (error) {
        console.log(error)
      }
    } else {
      setSelectItemFirstDialog(true)
    }
  }

  const handleImportButtonClick = () => {
    if (csvFileInputRef.current) {
      csvFileInputRef.current.click()
    }
  }
  const uploadFile = async (file: File) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('file-encoding', codec)
    const response = await fetch.post('csv/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  }
  const importFileMutation = useMutation({
    mutationFn: (file: File) => uploadFile(file),
    onSuccess: ({ data }) => {
      switch (data.type) {
        case 'ERROR':
          setImportResponseMessage(`${data.row}${data.title} ${data.content}`)
          break
        case 'INFO':
          setImportResponseMessage(`${data.title} ${data.content}`)
          break
        case 'ENCODING':
          setImportResponseMessage(data.content)
      }
      setSelectItemFirstDialog(true)
      refetch()
    },
    onError: (error) => {
      console.log('error importing CSV:', error)
      // refetch()
    },
  })

  const handleImportFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      importFileMutation.mutate(file)
      // uploadFile(file)
    }
    if (csvFileInputRef.current) {
      csvFileInputRef.current.value = ''
    }
  }

  const deleteProducts = async () => {
    const params = createSearchParams({
      id: Object.keys(rowSelection).reverse(),
    })
    const resource = 'products'
    const action = 'bulk-delete'
    const url = `${resource}/${action}?${params}`
    await fetch.delete(url)
  }
  const deleteMutation = useMutation({
    mutationFn: deleteProducts,
    onSuccess: () => {
      refetch()
      setDeleteComplete(true)
    },
  })

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          {(superUser || !referenceUser) && (
            <>
              <Button
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                onClick={() => navigate('/register')}
              >
                {t('actions.new')}
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                color="primary"
                variant="contained"
                onClick={() => {
                  if (!isEmpty(rowSelection)) {
                    setDeleteConfirm(true)
                  } else {
                    setSelectItemFirstDialog(true)
                  }
                }}
              >
                {t('actions.delete')}
              </Button>
            </>
          )}
          <Button
            startIcon={<PrintIcon />}
            color="primary"
            variant="contained"
            onClick={printSelected}
          >
            {t('actions.print')}
          </Button>
          <PopupState variant="popper" popupId="batch-print-button-popup-menu">
            {(popupState) => (
              <Box>
                <ButtonGroup variant="contained">
                  <Badge badgeContent={printCount} color="secondary">
                    <Button
                      startIcon={<ListAltIcon />}
                      onClick={() => navigate('/print-list')}
                    >
                      {t('actions.batchPrint')}
                    </Button>
                  </Badge>
                  <Button size="small" {...bindTrigger(popupState)}>
                    <ArrowRightIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{
                    zIndex: (theme) => theme.zIndex.tooltip,
                  }}
                  transition
                  disablePortal
                  {...bindPopper(popupState)}
                  placement="right-start"
                  role={undefined}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      // @ts-ignore
                      sx={{
                        transformOrigin: 'center right',
                      }}
                      {...TransitionProps}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={popupState.close}>
                          <MenuList
                            id="split-button-menu"
                            autoFocusItem={popupState.isOpen}
                          >
                            <MenuItem
                              onClick={() => {
                                clearPrintProduct()
                                popupState.close()
                              }}
                            >
                              {t('actions.clear')}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Box>
            )}
          </PopupState>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl>
            <InputLabel id="codec-select-label">
              {t('preferences.codec')}
            </InputLabel>
            <Select
              labelId="codec-select-label"
              label={t('preferences.codec')}
              value={codec}
              onChange={handleSelectCodec}
              size="small"
            >
              {CODEC_OPTIONS.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            startIcon={<InputIcon />}
            color="primary"
            variant="contained"
            onClick={handleImportButtonClick}
          >
            {t('actions.import')}
          </Button>
          <input
            type="file"
            ref={csvFileInputRef}
            style={{ display: 'none' }}
            accept=".csv"
            onChange={handleImportFileChange}
          />
          <Button
            startIcon={<OutputIcon />}
            color="primary"
            variant="contained"
            onClick={handleCsvExport}
          >
            {t('actions.export')}
          </Button>
        </Stack>
      </Stack>
      <Paper sx={{ p: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <TextField
                label={t('products.fields.product_name')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('product_name', { required: false })}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <TextField
                label={t('products.fields.consignor__japanese_name')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('consignor', { required: false })}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <TextField
                label={t('products.fields.country_of_origin')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('country_of_origin', { required: false })}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <TextField
                label={t('products.fields.shipper')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('shipper', { required: false })}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label={t('products.fields.hs_code')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('hs_code', { required: false })}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <TextField
                label={t('products.fields.product_no')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('product_no', { required: false })}
              />
            </Grid>
            <Grid item xs={1}>
              <Controller
                name="page_size"
                control={control}
                rules={{ required: false }}
                defaultValue={30}
                render={({ field }) => (
                  <Select {...field} size="small" fullWidth>
                    <MenuItem value={30}>
                      {t('preferences.pageSize.30')}
                    </MenuItem>
                    <MenuItem value={50}>
                      {t('preferences.pageSize.50')}
                    </MenuItem>
                    <MenuItem value={100}>
                      {t('preferences.pageSize.100')}
                    </MenuItem>
                  </Select>
                )}
              ></Controller>
            </Grid>
            <Grid item xs={11}>
              <TextField
                label={t('common.filters.keyword')}
                variant="outlined"
                size="small"
                fullWidth
                {...register('keyword', { required: false })}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                type="submit"
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                fullWidth
              >
                {t('actions.search')}
              </Button>
            </Grid>
          </Grid>
        </form>
        <FormHelperText sx={{ marginTop: 1 }}>
          {t('messages.emptyFilter')}
        </FormHelperText>
      </Paper>
      <MaterialReactTable table={table} />
      <Stack direction="row" justifyContent="center">
        <Pagination
          count={data ? ceil(data.count / Number(page_size_param)) : 0}
          page={Number(page)}
          onChange={handlePageChange}
        />
      </Stack>
      <Dialog
        open={deleteConfirm}
        onClose={() => {
          setDeleteConfirm(false)
        }}
      >
        <DialogTitle>{t('actions.confirm')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('messages.bulkDelete', {
              count: Object.keys(rowSelection).length,
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteMutation.mutate()
              setDeleteConfirm(false)
            }}
            variant="contained"
            color="primary"
          >
            {t('actions.ok')}
          </Button>
          <Button
            onClick={() => {
              setDeleteConfirm(false)
            }}
            variant="contained"
            color="secondary"
          >
            {t('actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <MessageDialog
        openDialog={
          deleteComplete ||
          selectItemFirstDialog ||
          importResponseMessage !== ''
        }
        onDialogClose={() => {
          setDeleteComplete(false)
          setSelectItemFirstDialog(false)
          setImportResponseMessage('')
        }}
        message={
          importResponseMessage !== ''
            ? importResponseMessage
            : selectItemFirstDialog
            ? t('messages.selectItemFirst')
            : deleteMutation.isSuccess
            ? t('messages.success', {
                action: t('actions.delete'),
              })
            : t('messages.deleteFailed')
        }
      />
    </Stack>
  )
}
