import MasterListCommon from './common/MasterListCommon'
import { useMasterConfig } from './useMasterConfig'

export default function MasterList() {
  const config = useMasterConfig()
  if (!config) {
    return null
  }
  const { resource, fields, filters } = config
  return (
    <MasterListCommon resource={resource} fields={fields} filters={filters} />
  )
}
