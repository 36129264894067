import { first, last, set } from 'lodash'

import {
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

import {
  Box,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear'

import { bindPopper } from 'material-ui-popup-state'
import { bindFocus, usePopupState } from 'material-ui-popup-state/hooks'

import { useAPI } from '../../api'
import { ListQueryResult, Master } from '../../api/types'
import { useController, useFormState } from 'react-hook-form'

interface MasterSelectProps<T extends Master> {
  resource: 'consignors' | 'countries' | 'regulations' | 'shippers'
  getLabel: (option: T) => string
  name: string
  width?: number
  height?: number
}

export default function MasterSelect<T extends Master>({
  resource,
  getLabel,
  name,
  width = 300,
  height = 300,
}: MasterSelectProps<T>) {
  const {
    field: {
      value,
      onChange: fieldOnChange,
      onBlur: fieldOnBlur,
      name: fieldName,
      ref: fieldRef,
    },
    fieldState: { error },
  } = useController({ name })

  const { isSubmitSuccessful: disabled } = useFormState()

  const [inputValue, setInputValue] = useState('')
  const [debouncedInputValue] = useDebounce(inputValue, 500)

  const [selected, setSelected] = useState<T | null>(null)

  const [focusIndex, setFocusIndex] = useState<number | null>(null)

  const { t } = useTranslation()

  const api = useAPI()
  const popupState = usePopupState({
    variant: 'popper',
    popupId: `${resource}-select-popper`,
  })

  const fetchOption = useCallback(
    ({ queryKey: [resource, params], pageParam }: any) =>
      api.getList<T>(`effective-${resource}`, {
        ...params,
        page: pageParam,
        page_size: 10,
      }),
    [api]
  )

  const fetchOne = useCallback(
    (resource: string, id: string | number) =>
      api.getOne<T>(`effective-${resource}`, id),
    [api]
  )

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useInfiniteQuery<ListQueryResult<T>>({
      queryKey: [resource, { search: debouncedInputValue }],
      queryFn: fetchOption,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => {
        return lastPage.next
      },
    })

  const allRows = useMemo(
    () => (data ? data.pages.flatMap((page) => page.results) : []),
    [data]
  )

  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
    overscan: 5,
  })

  const virturalItems = rowVirtualizer.getVirtualItems()

  useEffect(() => {
    if (popupState.isOpen) {
      rowVirtualizer.measure()
    }
  }, [popupState.isOpen, rowVirtualizer])

  useEffect(() => {
    const lastItem = last(virturalItems)
    if (!lastItem) {
      return
    }
    if (
      lastItem.index >= allRows.length - 1 &&
      hasNextPage &&
      !isFetchingNextPage
    ) {
      fetchNextPage()
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    virturalItems,
  ])

  const { onBlur: popupOnBlur, ...popupProps } = bindFocus(popupState)

  const onInputBlur = async (e: FocusEvent<Element, Element>) => {
    // 1. handle popup status
    if (e.relatedTarget !== parentRef.current) {
      popupOnBlur(e)
    }
    // 2. handle value
    if (!selected && inputValue) {
      try {
        const master = await fetchOne(resource, inputValue)
        setSelected(master)
      } catch (error) {
        setInputValue('')
      }
    }
    fieldOnBlur()
  }

  useEffect(() => {
    if (selected) {
      setInputValue(selected.code)
      fieldOnChange(selected.code)
    } else {
      setInputValue('')
      fieldOnChange('')
    }
  }, [selected, fieldOnChange])

  useEffect(() => {
    if (!value) {
      setSelected(null)
      setInputValue('')
    }
  }, [value])

  // set selected
  const confirmInput = useCallback(() => {
    if (focusIndex === null) {
      return
    }
    const master = allRows[focusIndex]
    setSelected(master)
    popupState.close()
  }, [allRows, focusIndex, popupState])

  // key down
  const inputKeydownHandler = useCallback(
    (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const virturalItems = rowVirtualizer.getVirtualItems()
      // if is down arrow
      if (e.key === 'ArrowDown') {
        e.preventDefault()
        if (focusIndex === null) {
          const firstItem = first(virturalItems)
          if (firstItem) {
            setFocusIndex(firstItem.index)
          }
        } else {
          const nextIndex = focusIndex + 1
          if (nextIndex < allRows.length) {
            setFocusIndex(nextIndex)
            const item = virturalItems.find(
              (virtualItem) => virtualItem.index === nextIndex
            )
            if (
              item &&
              item.start + item.size >
                parentRef.current!.clientHeight + parentRef.current!.scrollTop
            ) {
              rowVirtualizer.scrollToIndex(nextIndex)
            }
          }
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault()
        if (focusIndex !== null) {
          const nextIndex = focusIndex - 1
          if (nextIndex >= 0) {
            setFocusIndex(nextIndex)
            const item = virturalItems.find(
              (virtualItem) => virtualItem.index === nextIndex
            )
            if (item && item.start < parentRef.current!.scrollTop) {
              rowVirtualizer.scrollToIndex(nextIndex)
            }
          }
        }
      } else if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        confirmInput()
      }
    },
    [rowVirtualizer, focusIndex, allRows.length, confirmInput]
  )

  return (
    <ClickAwayListener onClickAway={popupState.close}>
      <Box>
        <TextField
          {...popupProps}
          disabled={disabled}
          label={t(`invoices.filters.${name}`)}
          name={fieldName}
          ref={fieldRef}
          fullWidth
          value={inputValue}
          onChange={(e) => {
            setSelected(null)
            setInputValue(e.target.value)
            if (!popupState.isOpen) {
              popupState.open()
            }
          }}
          onBlur={onInputBlur}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && !disabled && (
                  <IconButton onClick={() => setSelected(null)}>
                    <ClearIcon />
                  </IconButton>
                )}
                {selected && (
                  <Typography color="info">{`${getLabel(
                    selected
                  )}`}</Typography>
                )}
              </InputAdornment>
            ),
            onKeyDown: inputKeydownHandler,
          }}
        />
        {!disabled && (
          <Popper
            {...bindPopper(popupState)}
            sx={{ width: popupState.anchorEl?.clientWidth }}
            placement="bottom"
          >
            <Paper sx={{ width, p: 1 }}>
              <Box
                ref={parentRef}
                tabIndex={-1}
                sx={{
                  height,
                  width: `100%`,
                  overflow: 'auto',
                }}
              >
                <Box
                  sx={{
                    height: `${rowVirtualizer.getTotalSize()}px`,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {isFetching ? (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  ) : (
                    virturalItems.map((virtualRow) => {
                      const isLoaderRow = virtualRow.index > allRows.length - 1
                      const master = allRows[virtualRow.index]
                      return (
                        <Box
                          key={virtualRow.index}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: `${virtualRow.size}px`,
                            transform: `translateY(${virtualRow.start}px)`,
                            display: 'flex',
                            justifyContent: 'left',
                            alignItems: 'center',
                            backgroundColor:
                              focusIndex === virtualRow.index
                                ? 'primary.light'
                                : 'transparent',
                          }}
                          onClick={confirmInput}
                          onMouseEnter={() => setFocusIndex(virtualRow.index)}
                        >
                          {isLoaderRow
                            ? hasNextPage
                              ? t('messages.loading')
                              : null
                            : `${master.code} - ${getLabel(master)}`}
                        </Box>
                      )
                    })
                  )}
                </Box>
              </Box>
            </Paper>
          </Popper>
        )}
      </Box>
    </ClickAwayListener>
  )
}
