import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type TokenStore = {
  token: string | null
  setToken: (token: string) => void
  removeToken: () => void
}

const useTokenStore = create<TokenStore>()(
  devtools(
    persist(
      (set) => ({
        token: null,
        setToken: (token) => set({ token }),
        removeToken: () => set({ token: null }),
      }),
      { name: 'token-storage' }
    )
  )
)

export default useTokenStore
