import axios from 'axios'

import useTokenStore from '../store/tokenStore'

const fetch = axios.create({
  baseURL: `${process.env.REACT_APP_API_PREFIX}`,
})

fetch.interceptors.request.use((config) => {
  if (!config.headers['Content-Type'])
    config.headers['Content-Type'] = 'application/json'
  // if is login, do not add token
  if (config.url === '/auth/login/') return config
  const token = useTokenStore.getState().token
  if (token) {
    config.headers['Authorization'] = `Token ${token}`
  }
  return config
})

fetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      useTokenStore.getState().removeToken()
    }
    return Promise.reject(error)
  }
)

export default fetch
