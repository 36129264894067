import MasterNewCommon from './common/MasterNewCommon'
import { useMasterConfig } from './useMasterConfig'

export default function MasterNew() {
  const config = useMasterConfig()
  if (!config) {
    return null
  }
  const { resource, fields } = config
  return <MasterNewCommon resource={resource} fields={fields} />
}
