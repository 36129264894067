import { Controller, useFormContext } from 'react-hook-form'

import {
  Checkbox,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'

import { TextChoiceInputProps } from '../types'

export default function TextChoiceInput({
  label,
  name,
  printName,
  check = true,
  choiceName,
  choices,
}: TextChoiceInputProps) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          label={label}
          fullWidth
          {...field}
          InputProps={{
            startAdornment:
              check === null ? undefined : (
                <InputAdornment position="start">
                  <Controller
                    control={control}
                    disabled={!check}
                    name={printName ?? `print_${name}`}
                    render={({ field: { value, ...fieldProps } }) => (
                      <Checkbox {...fieldProps} checked={value} />
                    )}
                  />
                </InputAdornment>
              ),
            endAdornment: (
              <InputAdornment position="end">
                <Controller
                  control={control}
                  name={choiceName}
                  render={({ field: { value, onChange, ...fieldProps } }) => (
                    <Select
                      {...fieldProps}
                      value={value === null ? '' : value}
                      onChange={(e) =>
                        e.target.value === ''
                          ? onChange(null)
                          : onChange(e.target.value)
                      }
                      variant="standard"
                      displayEmpty
                    >
                      {choices.map((choice) => (
                        <MenuItem key={choice.value} value={choice.value}>
                          {choice.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
