import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { repeat } from 'lodash'

const UPDATE_FIELD_CHOICES = [
  {
    field: 'jan_code',
    length: 500,
  },
  {
    field: 'product_no',
    length: 500,
  },
  {
    field: 'product_name',
    length: 500,
  },
  {
    field: 'material',
    length: 500,
  },
  {
    field: 'usage',
    length: 500,
  },
  {
    field: 'other_regulation1',
    length: 100,
  },
  {
    field: 'other_regulation2',
    length: 100,
  },
  {
    field: 'other_regulation3',
    length: 100,
  },
  {
    field: 'hs_code',
    length: 100,
  },
  {
    field: 'tariff_rate',
    length: 10,
  },
  {
    field: 'memo01',
    length: 500,
  },
  {
    field: 'memo02',
    length: 500,
  },
  {
    field: 'memo03',
    length: 500,
  },
  {
    field: 'memo04',
    length: 500,
  },
  {
    field: 'memo05',
    length: 500,
  },
  {
    field: 'memo06',
    length: 500,
  },
  {
    field: 'memo07',
    length: 500,
  },
  {
    field: 'memo08',
    length: 500,
  },
  {
    field: 'memo09',
    length: 500,
  },
  {
    field: 'memo10',
    length: 500,
  },
  { field: 'certificate_of_origin', length: 100 },
  { field: 'certificate_status', length: 1 },
]

const CERTIFICATE_STATUS_CHOICES = ['1', '0']

export default function UpdateInput() {
  const { t } = useTranslation()

  const updateField = useWatch({ name: 'field' })

  return (
    <Grid container item spacing={2}>
      <Grid item xs={3.5}>
        <Controller
          name="field"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              size="small"
              displayEmpty
              fullWidth
              error={fieldState.invalid}
            >
              <MenuItem value="">{repeat('-', 10)}</MenuItem>
              {UPDATE_FIELD_CHOICES.map(({ field }) => (
                <MenuItem key={field} value={field}>
                  {t(`products.fields.${field}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="from_value"
          render={({ field, fieldState }) =>
            updateField === 'certificate_status' ? (
              <Select
                {...field}
                size="small"
                fullWidth
                displayEmpty
                error={fieldState.invalid}
              >
                <MenuItem value="">{repeat('-', 10)}</MenuItem>
                {CERTIFICATE_STATUS_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {t(`products.fields.certificate_status_choices.${choice}`)}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                {...field}
                size="small"
                fullWidth
                error={fieldState.invalid}
                inputProps={{
                  maxLength: UPDATE_FIELD_CHOICES.find(
                    ({ field: f }) => f === updateField
                  )?.length,
                }}
              />
            )
          }
        />
      </Grid>
      <Grid
        item
        xs={0.5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center">⇒</Typography>
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="to_value"
          render={({ field, fieldState }) =>
            updateField === 'certificate_status' ? (
              <Select
                {...field}
                size="small"
                fullWidth
                displayEmpty
                error={fieldState.invalid}
              >
                <MenuItem value="">{repeat('-', 10)}</MenuItem>
                {CERTIFICATE_STATUS_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {t(`products.fields.certificate_status_choices.${choice}`)}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <TextField
                {...field}
                size="small"
                fullWidth
                error={fieldState.invalid}
                inputProps={{
                  maxLength: UPDATE_FIELD_CHOICES.find(
                    ({ field: f }) => f === updateField
                  )?.length,
                }}
              />
            )
          }
        />
      </Grid>
    </Grid>
  )
}
