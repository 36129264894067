import { Controller, useFormContext } from 'react-hook-form'

import { Checkbox, InputAdornment, TextField } from '@mui/material'

import { TextInputProps } from '../types'

export default function TextInput({
  label,
  name,
  printName,
  check = false,
  ...multi
}: TextInputProps) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextField
          label={label}
          fullWidth
          {...field}
          {...multi}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{
            startAdornment:
              check === null ? undefined : (
                <InputAdornment position="start">
                  <Controller
                    control={control}
                    disabled={check}
                    name={printName ?? `print_${name}`}
                    render={({ field: { value, ...fieldProps } }) => (
                      <Checkbox {...fieldProps} checked={value} />
                    )}
                  />
                </InputAdornment>
              ),
          }}
        />
      )}
    />
  )
}
