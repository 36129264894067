import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type AuthStore = {
  isAuthenticated: boolean
  setIsAuthenticated: (isAuthenticated: boolean) => void
  user: any
  setUser: (user: any) => void
  superUser: boolean
  referenceUser: boolean
  loginUser: string
}

const useAuthStore = create<AuthStore>()(
  devtools(
    (set) => ({
      isAuthenticated: false,
      user: null,
      setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
      setUser: (user) =>
        set((state) => ({
          user,
          superUser: user?.is_superuser || false,
          referenceUser: user?.is_referenceuser || false,
          loginUser: user?.full_name || '',
        })),
      superUser: false,
      referenceUser: false,
      loginUser: '',
    }),
    { name: 'auth-storage' }
  )
)

export default useAuthStore
