import toFormData from 'json-form-data'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  ButtonGroup,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material'
import Stack from '@mui/system/Stack'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useAPI } from '../../api'
import { useTranslation } from 'react-i18next'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { MuiFileInput } from 'mui-file-input'
import i18n from '../../i18n'
import { useRef } from 'react'
import { ClearIcon } from '@mui/x-date-pickers'

interface NACCSFormValues {
  invoice: File | null
  format: File | null
}

function FileInput({
  name,
  label,
  accept,
}: {
  name: string
  label: string
  accept: string
}) {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <MuiFileInput
          label={label}
          fullWidth
          {...field}
          placeholder={t(`naccs.fields.${name}`) + t(`actions.select`)}
          hideSizeText
          inputRef={inputRef}
          inputProps={{
            accept,
          }}
          InputProps={{
            endAdornment: field.value && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    field.onChange(null)
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}

const schema = yup
  .object({
    invoice: yup
      .mixed()
      .required(
        i18n.t('validation.required', { field: i18n.t('naccs.fields.invoice') })
      ),
    format: yup.mixed().notRequired(),
  })
  .required()

export default function NACCS() {
  const api = useAPI()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const form = useForm<NACCSFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      invoice: null,
      format: null,
    },
  })

  const exportMutation = useMutation({
    mutationFn: (data: NACCSFormValues) => {
      const fileNameTxt = data.invoice?.name.replace(/\.[^/.]+$/, '.txt') // change the xls file name to txt
      return api.toFile('naccs', toFormData(data as any), true, fileNameTxt)
    },
    onSuccess: () => {
      enqueueSnackbar(t('messages.success', { action: t('actions.export') }), {
        variant: 'success',
      })
    },
  })

  const onSubmit = form.handleSubmit((data: NACCSFormValues) =>
    exportMutation.mutate(data)
  )

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <Stack spacing={4}>
          <Typography variant="h6">{t('naccs.title')}</Typography>
          <Stack spacing={4}>
            <FileInput
              name="invoice"
              label={t('naccs.fields.invoice')}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <FileInput
              name="format"
              label={t('naccs.fields.format')}
              accept="text/plain"
            />
          </Stack>
          <Stack flexDirection="row-reverse">
            <Button
              variant="contained"
              type="submit"
              disabled={exportMutation.isPending}
            >
              {t('actions.naccsExport')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
