import React from 'react'
import { AxiosHeaders } from 'axios'
import fetch from './axios'
import useTokenStore from '../store/tokenStore'
import { saveAs } from 'file-saver'
import { ListQueryResult } from './types'
import { isArray } from 'lodash'

export type APIContextType = {
  login: (username: string, password: string) => Promise<void>
  logout: () => Promise<void>
  getUser: () => Promise<any>
  getList: <T>(resource: string, params: any) => Promise<ListQueryResult<T>>
  getOne: <T>(resource: string, id: number | string) => Promise<T>
  create: <T>(resource: string, data: any, hasFile?: boolean) => Promise<T>
  postWithAction: <T>(resource: string, action: string, data: any) => Promise<T>
  update: <T>(
    resource: string,
    id: number | string,
    data: any,
    hasFile?: boolean
  ) => Promise<T>
  delete: (resource: string, id: number | string) => Promise<any>
  toFile: (
    resource: string,
    data: any,
    hasFile?: boolean,
    fileMei?: string
  ) => Promise<void>
  [method: string]: (...args: any[]) => Promise<any>
}

const APIContext = React.createContext<APIContextType | null>(null)

function base64EncodeUnicode(str: string): string {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
      String.fromCharCode(parseInt(p1, 16))
    )
  )
}

export const useAPI = () => {
  const api = React.useContext(APIContext)
  if (!api) throw new Error('useAPI must be used within APIProvider')
  return api
}

export const APIProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { removeToken, setToken } = useTokenStore()

  const apiContext = React.useMemo(
    () => ({
      login: async (username: string, password: string) => {
        const encodedCredentials = base64EncodeUnicode(
          `${username}:${password}`
        )
        // use http basic auth
        const response = await fetch.post(
          '/auth/login/',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Basic ${encodedCredentials}`,
            },
          }
        )
        const { token = null } = response.data
        setToken(token)
      },
      logout: async () => {
        await fetch.post('/auth/logout/')
        removeToken()
      },
      getUser: () => fetch.get('/auth/user/'),
      getList: async (resource: string, params: Record<string, string>) => {
        const response = await fetch.get(
          `${resource}/?${new URLSearchParams(params).toString()}`
        )
        const data = response.data
        if (isArray(data)) {
          return {
            count: data.length,
            next: null,
            previous: null,
            results: data,
          }
        }
        return data
      },
      updateMany: async (
        resource: string,
        params: Record<string, string>,
        payload: any
      ) => {
        console.log(params)
        const response = await fetch.put(`${resource}/`, payload, {
          params,
          paramsSerializer: {
            indexes: null,
          },
        })
        return response.data
      },
      getPrintList: async (resource: string, idList: string[]) => {
        const queryParams = new URLSearchParams()
        if (idList.length === 0) {
          return []
        }
        idList.forEach((id) => queryParams.append('id', id))

        const response = await fetch.get(`${resource}/?${queryParams}`)

        const data = response.data

        if (Array.isArray(data)) {
          return {
            count: data.length,
            next: null,
            previous: null,
            results: data,
          }
        }

        return data
      },
      getOne: async (resource: string, id: number | string) => {
        const response = await fetch.get(`${resource}/${id}/`)
        return response.data
      },
      create: async (
        resource: string,
        payload: any,
        hasFile: boolean = false
      ) => {
        if (hasFile) {
          const response = await fetch.post(`${resource}/`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          return response.data
        } else {
          const response = await fetch.post(`${resource}/`, payload)
          return response.data
        }
      },
      postWithAction: async (
        resource: string,
        action: string,
        payload: any
      ) => {
        const response = await fetch.post(`${resource}/${action}/`, payload)
        return response.data
      },
      update: async (
        resource: string,
        id: number | string,
        payload: any,
        hasFile = false
      ) => {
        if (hasFile) {
          const response = await fetch.patch(`${resource}/${id}/`, payload, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          return response.data
        } else {
          const response = await fetch.patch(`${resource}/${id}/`, payload)
          return response.data
        }
      },
      delete: (resource: string, id: number | string) =>
        fetch.delete(`${resource}/${id}/`),
      toFile: async (
        resource: string,
        data: any,
        hasFile: boolean = false,
        fileMei?: string
      ) => {
        let response
        if (hasFile) {
          response = await fetch.post(`${resource}/`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob',
            responseEncoding: 'binary',
          })
        } else {
          response = await fetch.post(`${resource}/`, data, {
            responseType: 'blob',
            responseEncoding: 'binary',
          })
        }
        const mineType = response.headers['content-type']
        const fileName =
          fileMei !== ''
            ? fileMei
            : response.headers['content-disposition'].split('=')[1] ?? resource
        const blob = new Blob([response.data], { type: mineType })
        saveAs(blob, fileName)
      },
    }),
    [removeToken, setToken]
  )

  return (
    <APIContext.Provider value={apiContext}>{children}</APIContext.Provider>
  )
}
