import toFormData from 'json-form-data'

import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { Card, CardContent, CardHeader } from '@mui/material'

import { useAPI } from '../../../api'
import ProductForm from '../ProductForm'
import { ProductFormValues } from '../ProductForm/types'

export default function Register() {
  const { t } = useTranslation()
  const api = useAPI()

  const onSubmit = useCallback(
    (data: ProductFormValues) => {
      return api.create('products', toFormData(data), true)
    },
    [api]
  )

  return (
    <Card>
      <CardHeader title={t(`products.titles.register`)} />
      <CardContent>
        <ProductForm action="register" onSubmit={onSubmit} />
      </CardContent>
    </Card>
  )
}
