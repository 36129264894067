import { useMask } from '@react-input/mask'
import { InputProps, MaskInputProps } from '../types'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, InputAdornment, TextField } from '@mui/material'

export default function MaskInput({
  maskProps,
  label,
  name,
  printName,
  check = true,
}: MaskInputProps) {
  const { control } = useFormContext()
  const inputRef = useMask(maskProps)

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <TextField
          label={label}
          fullWidth
          {...field}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          inputRef={inputRef}
          placeholder={maskProps.mask}
          InputProps={{
            startAdornment:
              check === null ? undefined : (
                <InputAdornment position="start">
                  <Controller
                    control={control}
                    disabled={!check}
                    name={printName ?? `print_${name}`}
                    render={({ field: { value, ...fieldProps } }) => (
                      <Checkbox {...fieldProps} checked={value} />
                    )}
                  />
                </InputAdornment>
              ),
          }}
        />
      )}
    />
  )
}
