import React from 'react'
import { useAPI } from '../../api'
import {
  QueryClient,
  QueryClientProvider,
  QueryFunctionContext,
} from '@tanstack/react-query'
import AuthGuard from './AuthGuard'
import Frame from './Frame'

type QueryKey = readonly [string, string, any]

const Root: React.FC = () => {
  const api = useAPI()
  const defaultQueryFn = React.useCallback(
    ({ queryKey }: QueryFunctionContext) => {
      const [method, resource, params] = queryKey as QueryKey
      return api[method](resource, params)
    },
    [api]
  )
  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            queryFn: defaultQueryFn,
            refetchOnWindowFocus: false,
          },
        },
      }),
    [defaultQueryFn]
  )

  return (
    <QueryClientProvider client={queryClient}>
      <AuthGuard>
        <Frame />
      </AuthGuard>
    </QueryClientProvider>
  )
}

export default Root
