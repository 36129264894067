import {create} from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type DrawerStore = {
  open: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  toggleDrawer: () => void;
};

const useDrawerStore = create<DrawerStore>()(
  devtools(
    persist(
      (set) => ({
        open: false,
        openDrawer: () => set({ open: true }),
        closeDrawer: () => set({ open: false }),
        toggleDrawer: () => set((state) => ({ open: !state.open })),
      }),
      { name: 'drawer-storage' }
    )
  )
);

export default useDrawerStore;
