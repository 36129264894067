const ja = {
  menus: {
    search: '検索',
    batch: '一括更新',
    invoice: 'インボイス',
    naccs: 'NACCS',
    consignor: '荷主マスタメンテ',
    shipper: 'SHIPPERマスタメンテ',
    countries: '原産国マスタメンテ',
    regulations: '他法令マスタメンテ',
    register: '商品登録',
    userMaster: 'ユーザーマスタメンテ',
  },
  login: {
    fields: {
      userName: 'ユーザ名',
      password: 'パスワード',
    },
  },
  actions: {
    login: 'ログイン',
    new: '新規',
    register: '登録',
    create: '登録',
    repeatRegister: '連続登録',
    search: '検索',
    delete: '削除',
    clear: 'クリア',
    print: '印刷',
    sort: '並替',
    batchPrint: '一括印刷',
    saveTemplate: '保存',
    listPrint: ' 一覧印刷',
    import: 'データ取込',
    export: 'データ出力',
    select: '選択',
    showPrevious: '変更前',
    update: '更新',
    copy: 'コピー',
    add: '追加',
    cancel: 'キャンセル',
    inputStart: '入力開始',
    cut: '切り取り',
    paste: '貼り付け',
    deleteRow: '行削除',
    insertRowBelow: '下に行を挿入',
    duplicateRow: '行複製',
    naccsExport: 'NACCSデータ出力',
    confirm: '確認',
    ok: 'OK',
    yes: 'はい',
    no: 'いいえ',
  },
  preferences: {
    codec: '文字コード',
    pageSize: {
      30: '30件',
      50: '50件',
      100: '100件',
    },
    units: {
      row: '行',
    },
    table: {
      columns: {
        action: '操作',
      },
    },
    conditions: {
      and: '全てに一致',
      or: 'いずれかに一致',
    },
  },
  common: {
    filters: {
      keyword: 'キーワード',
    },
    fields: {
      select: '選択',
      created_at: '作成日時',
      updated_at: '最終更新日',
      created_by: '作成者',
      updated_by: '最終更新者',
    },
    dialogs: {
      leaveBeforeSave: {
        title: 'このページを離れますか？',
        content: '行った変更が保存されない可能性があります。',
        actions: {
          leave: 'このページを離れる',
        },
      },
    },
  },
  printLists: {
    printTemplateList: '登録リスト',
    templateName: '登録リスト名',
    nullNameMessage: '登録リスト名は必須入力です。',
    noDataMessage: 'データがありません',
  },
  products: {
    titles: {
      register: '新規登録',
      update: '更新',
      copy: '複写',
      description: '商品説明',
      image: '画像イメージ',
    },
    fields: {
      consignor: '荷主',
      consignor__japanese_name: '荷主（和名）',
      consignor__english_name: '荷主（英名）',
      shipper: 'SHIPPER',
      jan_code: 'JAN CODE',
      product_no: '商品NO',
      product_name: '品名',
      material: '商品情報',
      usage: '用途',
      country_of_origin: '原産国',
      other_regulation1: '他法令1',
      other_regulation2: '他法令2',
      other_regulation3: '他法令3',
      hs_code: '税番',
      tariff_rate: '税率',
      memo01: '備考①',
      memo02: '備考②',
      memo03: '備考③',
      memo04: '備考④',
      memo05: '備考⑤',
      memo06: '備考⑥',
      memo07: '備考⑦',
      memo08: '備考⑧',
      memo09: '備考⑨',
      memo10: '備考⑩',
      certificate_of_origin: '原産地証明書',
      certificate_of_origin_short: '原産地',
      imageIndicator: '画像',
      certificate_status: '原産地証明書選択',
      certificate_of_origin_memo: '原産地証明書備考',
      customs_inquiry_history: '税関問合せ履歴',
      print_image: '印刷_画像ファイル',
      print_consignor_en: '印刷_荷主英名',
      print_consignor_jp: '印刷_荷主和名',
      print_shipper: '印刷_SHIPPER',
      print_jan_code: '印刷_JANコード',
      print_product_no: '印刷_商品NO',
      print_product_name: '印刷_品名',
      print_material: '印刷_材質',
      print_usage: '印刷_用途',
      print_country_of_origin: '印刷_原産国',
      print_other_regulation1: '印刷_他法令1',
      print_other_regulation2: '印刷_他法令2',
      print_other_regulation3: '印刷_他法令3',
      print_hs_code: '印刷_税番',
      print_tariff_rate: '印刷_関税',
      print_memo01: '印刷_備考①',
      print_memo02: '印刷_備考②',
      print_memo03: '印刷_備考③',
      print_memo04: '印刷_備考④',
      print_memo05: '印刷_備考⑤',
      print_memo06: '印刷_備考⑥',
      print_memo07: '印刷_備考⑦',
      print_memo08: '印刷_備考⑧',
      print_memo09: '印刷_備考⑨',
      print_memo10: '印刷_備考⑩',
      print_certificate_of_origin: '印刷_原産地証明書',
      print_customs_inquiry_history: '印刷_税関問合せ履歴',
      image: '画像ファイル',
      certificate_status_choices: { 1: '〇', 0: '✖' },
    },
    filters: {
      keyword: 'キーワード',
      consignor__english_name: '荷主(英名)',
      consignor__japanese_name: '荷主(和名)',
      shipper__name: 'SHIPPER',
      jan_code: 'JANCODE',
      product_no: '商品NO',
      product_name: '品名',
      material: '商品情報',
      usage: '用途',
      country_of_origin__name: '原産国',
      other_regulation1__name: '他法令１',
      other_regulation2__name: '他法令２',
      other_regulation3__name: '他法令３',
      hs_code: '税番',
      tariff_rate: '税率',
      memo01: '備考①',
      memo02: '備考②',
      memo03: '備考③',
      memo04: '備考④',
      memo05: '備考⑤',
      memo06: '備考⑥',
      memo07: '備考⑦',
      memo08: '備考⑧',
      memo09: '備考⑨',
      memo10: '備考⑩',
      certificate_of_origin: '原産地証明書',
      certificate_status: '原産地証明書選択',
    },
    errors: {
      404: 'この商品はほかのユーザーにより削除されているため、更新できません。',
    },
  },
  invoices: {
    title: 'インボイス入力',
    filters: {
      consignor: '荷主',
      shipper: 'SHIPPER',
    },
    fields: {
      checked: '選択',
      product: '商品',
      quantity: '数量',
      unit_price: '単価',
      total_price: '金額',
      hs_code: '税番',
      material: '商品情報',
      usage: '用途',
      jan_code: 'JANCODE',
    },
  },
  naccs: {
    title: 'NACCSデータ生成',
    fields: {
      invoice: 'Excelファイル',
      format: '追加NACCSフォーマットファイル',
    },
  },
  masters: {
    common: {
      fields: {
        code: 'コード',
        apply_date: '適用開始日',
      },
      title: {
        list: 'マスタメンテナンス',
        detail: 'マスタメンテナンス詳細',
      },
    },
    consignors: {
      name: '荷主',
      fields: {
        japanese_name: '荷主（和名）',
        english_name: '荷主（英名）',
      },
    },
    shippers: {
      name: 'SHIPPER',
      fields: {
        name: '名称',
      },
    },
    regulations: {
      name: '他法令',
      fields: {
        name: '名称',
      },
    },
    countries: {
      name: '原産国',
      fields: {
        name: '名称',
      },
    },
  },
  batch: {
    filters: {
      filter: '検索項目',
      from: '値',
    },
    fields: {
      all: 'すべて更新',
    },
  },
  validations: {
    required: '{{field}}は必須です',
    atLeastOne: '{{field}}は少なくとも1つ必要です',
    requiredIfSelected: '選択されている場合、{{field}}が必須です',
    noDuplicate: '{{field}}は重複しないように入力してください',
    uniqueApplyDate: 'コード と 適用開始日 を持った データ が既に存在します',
  },
  messages: {
    success: '{{action}}完了しました',
    emptyFilter:
      '条件を入れずに検索すると、時間がかかってしまうことがあります。',
    selectFile: 'ファイルを選択してください',
    loading: '読み込み中...',
    batchConfirm:
      '一括更新します。\n更新されたデータは元に戻すことはできません。\n本当によろしいですか？',
    batchFinished: '一括更新が完了しました。更新された商品数：{{count}}',
    bulkDelete: '{{count}}つのアイテムを削除します。よろしいですか？',
    batchFailed: '一括更新が失敗しました。',
    deleteFailed: '削除が失敗しました。',
    selectItemFirst: '行を選択してください',
  },
  errors: {
    409: '他のユーザーが更新したため、再度検索してください。',
    404: 'データが見つかりませんでした。',
  },
}

export default ja
