import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import Root from '../components/Root'
import Login from '../components/Login'

import Search from '../components/product/Search'
import Register from '../components/product/Register'

import Invoice from '../components/Invoice'

import MasterList from '../components/masters/MasterList'
import MasterNew from '../components/masters/MasterNew'
import MasterAppend from '../components/masters/MasterAppend'

import BulkUpdate from '../components/product/BulkUpdate'
import Update from '../components/product/Update'
import Copy from '../components/product/Copy'

import PrintProduct from '../components/PrintScreen/PrintProduct'
import PrintList from '../components/PrintScreen/PrintList'
import NACCS from '../components/NACCS'
import BatchUpdate from '../components/BatchUpdate'
const routeConfig: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      { index: true, element: <Navigate to="/search" /> },
      { path: 'search', element: <Search /> },
      { path: 'register', element: <Register /> },
      { path: 'update/:id', element: <Update /> },
      { path: 'copy/:id', element: <Copy /> },
      { path: 'print', element: <PrintProduct /> },
      { path: 'print-list', element: <PrintList /> },
      { path: 'bulk-update', element: <BulkUpdate /> },
      { path: 'invoice', element: <Invoice /> },
      { path: 'naccs', element: <NACCS /> },
      { path: 'batch', element: <BatchUpdate /> },
      {
        path: 'masters',
        element: <Outlet />,
        children: [
          {
            path: ':resource',
            element: <Outlet />,
            children: [
              { index: true, element: <MasterList /> },
              { path: 'new', element: <MasterNew /> },
              { path: ':code', element: <MasterAppend /> },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]

export default routeConfig
