import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@emotion/react'

import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { SnackbarProvider } from 'notistack'

import 'react-datasheet-grid/dist/style.css'

import './index.css'
import routeConfig from './route'
import { APIProvider } from './api'
import './i18n'
import theme from './theme'

const router = createBrowserRouter(routeConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <APIProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            autoHideDuration={5000}
          >
            <RouterProvider router={router} />
          </SnackbarProvider>
        </LocalizationProvider>
      </APIProvider>
    </ThemeProvider>
  </React.StrictMode>
)
