import dayjs from 'dayjs'
import _, { repeat } from 'lodash'

import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import ReactRouterPrompt from 'react-router-prompt'

import { useMutation } from '@tanstack/react-query'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { t } from 'i18next'

import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'

import { InitialDataProvider } from './InitialDataContext'
import TextInput from './inputs/TextInput'
import FileInput from './inputs/FileInput'
import MasterInput from './inputs/MasterInput'
import SelectInput from './inputs/SelectInput'
import MaskInput from './inputs/MaskInput'
import TextChoiceInput from './inputs/TextChoiceInput'
import ExpandableTextInput from './inputs/ExpandableTextInput'
import i18n from '../../../i18n'
import { ProductFormValues } from './types'

interface ProductFormProps {
  action: 'register' | 'update' | 'copy'
  initialData?: any
  onSubmit: (data: any) => Promise<any>
}

const ProductDefault: ProductFormValues = {
  image1: null,
  image2: null,
  image3: null,
  consignor: '',
  shipper: '',
  jan_code: '',
  product_no: '',
  product_name: '',
  material: '',
  usage: '',
  country_of_origin: '',
  other_regulation1: '',
  other_regulation2: '',
  other_regulation3: '',
  hs_code: '',
  memo01: '',
  memo02: '',
  memo03: '',
  memo04: '',
  memo05: '',
  memo06: '',
  memo07: '',
  memo08: '',
  memo09: '',
  memo10: '',
  tariff_rate: '',
  certificate_of_origin: '',
  certificate_status: null,
  custom_inquiry_history: '',

  print_image: true,
  print_consignor_en: true,
  print_consignor_jp: true,
  print_shipper: true,
  print_jan_code: false,
  print_product_no: true,
  print_product_name: true,
  print_material: true,
  print_usage: true,
  print_country_of_origin: true,
  print_other_regulation1: true,
  print_other_regulation2: false,
  print_other_regulation3: false,
  print_hs_code: false,
  print_memo01: true,
  print_memo02: false,
  print_memo03: false,
  print_memo04: false,
  print_memo05: false,
  print_memo06: false,
  print_memo07: false,
  print_memo08: false,
  print_memo09: false,
  print_memo10: false,
  print_tariff_rate: true,
  print_certificate_of_origin: true,
}

const schema = yup
  .object({
    consignor: yup.string().required(
      `${i18n.t('validations.required', {
        field: i18n.t('masters.consignors.fields.english_name'),
      })}`
    ),
    product_name: yup.string().required(
      `${i18n.t('validations.required', {
        field: i18n.t('products.fields.product_name'),
      })}`
    ),
    material: yup.string().required(`
      ${i18n.t('validations.required', {
        field: i18n.t('products.fields.material'),
      })}`),
  })
  .required()

export default function ProductForm({
  action,
  initialData,
  onSubmit,
}: ProductFormProps) {
  const navigate = useNavigate()

  const productDefault: ProductFormValues = useMemo(() => {
    if (action === 'register') {
      return ProductDefault
    } else {
      // merge productDefault with initialData if value is not null
      const productDefault = _.mapValues(ProductDefault, (value, key) => {
        if (key.match(/^image\d/) && action === 'copy') {
          return null
        }
        if (!_.isNil(initialData?.[key])) {
          return initialData?.[key]
        }
        return value
      })
      return productDefault
    }
  }, [action, initialData])

  const form = useForm<ProductFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: productDefault,
  })

  useEffect(() => {
    form.reset(productDefault)
  }, [form, productDefault])

  const handleRemoteError = useCallback(
    (error: any) => {
      const { setError } = form
      const { response } = error
      if (response) {
        const { status } = response
        if (status === 400) {
          // set error to form fields
          // especially, for nested fields like product_details
          // we set error with key like product_details[0].image_path
          Object.entries(response.data).forEach(([key, value]: any) => {
            // if (key === 'product_details') {
            //   value.forEach((detail: any, index: number) => {
            //     Object.entries(detail).forEach(
            //       ([detailKey, detailValue]: any) => {
            //         setError(`product_details[${index}].${detailKey}`, {
            //           type: 'remote',
            //           message: detailValue,
            //         })
            //       }
            //     )
            //   })
            // } else {
            setError(key, { type: 'remote', message: value })
            // }
          })
        } else if (status === 409) {
          setError('root.conflict', {
            type: 'remote',
            message: t('errors.409'),
          })
        } else if (status === 404) {
          setError('root.notFound', {
            type: 'remote',
            message: t('products.errors.404'),
          })
        }
      }
    },
    [form]
  )

  const mutation = useMutation({
    mutationFn: onSubmit,
    onSuccess: () => {
      navigate(-1)
    },
    onError: handleRemoteError,
  })

  const handleSubmit = form.handleSubmit(mutation.mutate as any)

  return (
    <InitialDataProvider value={initialData}>
      <FormProvider {...form}>
        <ReactRouterPrompt when={form.formState.isDirty && !mutation.isSuccess}>
          {({ isActive, onConfirm, onCancel }) => (
            <Dialog open={isActive}>
              <DialogTitle>
                {t('common.dialogs.leaveBeforeSave.title')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('common.dialogs.leaveBeforeSave.content')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={onConfirm}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  {t('common.dialogs.leaveBeforeSave.actions.leave')}
                </Button>
                <Button onClick={onCancel}>{t('actions.cancel')}</Button>
              </DialogActions>
            </Dialog>
          )}
        </ReactRouterPrompt>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <Collapse in={!_.isEmpty(form.formState.errors.root)}>
              <Stack spacing={2}>
                {form.formState.errors.root &&
                  _.entries(form.formState.errors.root!).map(
                    ([key, value]: [string, any]) => (
                      <Alert key={key} severity="error" variant="filled">
                        {value.message}
                      </Alert>
                    )
                  )}
              </Stack>
            </Collapse>
            <FileInput
              label={t('products.fields.image')}
              name="image1"
              printName="print_image"
            />
            <FileInput name="image2" check={false} printName="print_image" />
            <FileInput name="image3" check={false} printName="print_image" />
            <MasterInput
              resource="consignors"
              name="consignor"
              fields={[
                {
                  name: 'english_name',
                  printName: 'print_consignor_en',
                  entry: true,
                  label: t('masters.consignors.fields.english_name'),
                },
                {
                  name: 'japanese_name',
                  printName: 'print_consignor_jp',
                  entry: false,
                  label: t('masters.consignors.fields.japanese_name'),
                },
              ]}
            />
            <MasterInput
              resource="shippers"
              name="shipper"
              fields={[
                {
                  name: 'name',
                  printName: 'print_shipper',
                  entry: true,
                  label: t('products.fields.shipper'),
                },
              ]}
            />
            <TextInput label={t('products.fields.jan_code')} name="jan_code" />
            <TextInput
              label={t('products.fields.product_no')}
              name="product_no"
            />
            <TextInput
              label={t('products.fields.product_name')}
              name="product_name"
            />
            <TextInput label={t('products.fields.material')} name="material" />
            <TextInput label={t('products.fields.usage')} name="usage" />
            <SelectInput
              resource="countries"
              name="country_of_origin"
              label={t('products.fields.country_of_origin')}
            />
            <Stack direction="row" spacing={1}>
              <SelectInput
                resource="regulations"
                name="other_regulation1"
                label={t('products.fields.other_regulation1')}
              />
              <SelectInput
                resource="regulations"
                name="other_regulation2"
                label={t('products.fields.other_regulation2')}
              />
              <SelectInput
                resource="regulations"
                name="other_regulation3"
                label={t('products.fields.other_regulation3')}
              />
            </Stack>
            <MaskInput
              maskProps={{
                mask: '____.__-__________',
                replacement: { _: /[0-9a-zA-Z]/ },
              }}
              label={t('products.fields.hs_code')}
              name="hs_code"
            />
            <ExpandableTextInput
              label={t('products.fields.memo01')}
              name="memo01"
              expandFields={Array.from({ length: 9 }, (x, i) => ({
                label: t(
                  `products.fields.memo${(i + 2).toString().padStart(2, '0')}`
                ),
                name: `memo${(i + 2).toString().padStart(2, '0')}`,
              }))}
            />
            <TextInput
              label={t('products.fields.tariff_rate')}
              name="tariff_rate"
            />
            <TextChoiceInput
              label={t('products.fields.certificate_of_origin')}
              name="certificate_of_origin"
              choiceName="certificate_status"
              choices={[
                {
                  label: repeat('-', 10),
                  value: '',
                },
                {
                  label: t('products.fields.certificate_status_choices.1'),
                  value: '1',
                },
                {
                  label: t('products.fields.certificate_status_choices.0'),
                  value: '0',
                },
              ]}
            />
            <TextInput
              label={t('products.fields.customs_inquiry_history')}
              name="customs_inquiry_history"
              multiline
              rows={10}
              check={null}
            />

            {initialData && (
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Typography>{t('common.fields.updated_at')}</Typography>
                  <Typography>
                    {dayjs(initialData.updated_on).format(
                      'YYYY/MM/DD HH:mm:ss'
                    )}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography>{t('common.fields.updated_by')}</Typography>
                  <Typography>{initialData.updated_by}</Typography>
                </Stack>
              </Stack>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!_.isEmpty(form.formState.errors.root)}
            >
              {t(`actions.${action}`)}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </InitialDataProvider>
  )
}
