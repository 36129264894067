import { Controller, useFormContext } from 'react-hook-form'
import { SelectInputProps } from '../types'
import { Checkbox, InputAdornment, TextField, MenuItem } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { ListQueryResult, Master } from '../../../../api/types'
import { repeat } from 'lodash'

export default function SelectInput({
  resource,
  label,
  name,
  printName,
  check = true,
}: SelectInputProps) {
  const { control } = useFormContext()

  const { data } = useQuery<ListQueryResult<Master>>({
    queryKey: ['getList', `effective-${resource}`],
  })

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <TextField
          label={label}
          fullWidth
          {...field}
          select
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            startAdornment:
              check === null ? undefined : (
                <InputAdornment position="start">
                  <Controller
                    control={control}
                    disabled={!check}
                    name={printName ?? `print_${name}`}
                    render={({ field: { value, ...fieldProps } }) => (
                      <Checkbox {...fieldProps} checked={value} />
                    )}
                  />
                </InputAdornment>
              ),
          }}
        >
          <MenuItem value="">{repeat('-', 10)}</MenuItem>
          {data?.results.map((item) => (
            <MenuItem key={item.id} value={item.code}>
              {name === 'country_of_origin'
                ? `${item.code} : ${item.name}`
                : `${item.name}`}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}
