import React from 'react'

import { useForm } from 'react-hook-form'
import { useAPI } from '../../api'
import useTokenStore from '../../store/tokenStore'
import { Navigate } from 'react-router-dom'
import { Alert, Avatar, Box, Button, Paper, TextField } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { teal } from '@mui/material/colors'
import { t } from 'i18next'

interface LoginFormData {
  username: string
  password: string
}

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>()

  const api = useAPI()
  const [fetchError, setFetchError] = React.useState('')
  const onSubmit = handleSubmit((data) =>
    api.login(data.username, data.password).catch((err) => {
      if (err.response && err.response.data) {
        const firstErrorKey = Object.keys(err.response.data)[0]
        const errorMessage = err.response.data[firstErrorKey]
        setFetchError(errorMessage)
      } else {
        console.error('Unexpected error:', err)
      }
    })
  )

  const { token } = useTokenStore()
  if (token) {
    return <Navigate to="/search" />
  }

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: 4,
        }}
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Avatar sx={{ bgcolor: teal[400] }}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <Typography variant={'h5'} sx={{ m: '30px' }}>
            商品管理システム
          </Typography> */}
        </Box>
        <TextField
          label={t('login.fields.userName')}
          variant="standard"
          {...register('username', { required: true })}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit()
            }
          }}
        />
        {errors?.username && <p>{errors.username.message}</p>}
        <TextField
          type="password"
          label={t('login.fields.password')}
          variant="standard"
          {...register('password', { required: true, min: 6 })}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit()
            }
          }}
        />
        {errors?.password && <p>{errors.password.message}</p>}
        <Box mt={3}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={onSubmit}
            fullWidth
          >
            {t('actions.login')}
          </Button>
        </Box>
        {fetchError && (
          <Box mt={3}>
            <Alert severity="error">{fetchError}</Alert>
          </Box>
        )}
      </Paper>
    </Box>
  )
}
