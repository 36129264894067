import toFormData from 'json-form-data'

import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Card, CardContent, CardHeader } from '@mui/material'

import { Product } from '../../../api/types'
import { useAPI } from '../../../api'
import ProductForm from '../ProductForm'
import { ProductFormValues } from '../ProductForm/types'

export default function Copy() {
  const { t } = useTranslation()
  const api = useAPI()
  const { id } = useParams()

  const { data } = useQuery<Product>({
    queryKey: ['getOne', 'products', id],
  })

  const onSubmit = useCallback(
    (data: ProductFormValues) => {
      return api.create('products', toFormData(data), true)
    },
    [api]
  )

  return (
    <Card>
      <CardHeader title={t(`products.titles.copy`)} />
      <CardContent>
        <ProductForm action="copy" onSubmit={onSubmit} initialData={data} />
      </CardContent>
    </Card>
  )
}
