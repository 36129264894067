import {
  AppBar,
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
// import CommonSelect from '../../common/CommonSelect'
// import DataTable from '../common/DataTable'
import SearchIcon from '@mui/icons-material/Search'

const options = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
]

export default function BulkUpdate() {
  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <Card sx={{ mt: 3 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" component="div">
                一括更新
              </Typography>
            </Toolbar>
          </AppBar>
        </Card>
        <Card>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={12} md={4} lg={4}>
                {/* <CommonSelect label="検索" options={options} /> */}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  font: 20,
                }}
              >
                <Typography variant="h5">～</Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                {/* <CommonSelect label="全てに一致" options={options} /> */}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={12} md={4} lg={4}>
                {/* <CommonSelect label="検索" options={options} /> */}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  font: 20,
                }}
              >
                <Typography variant="h5">～</Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                {/* <CommonSelect label="検索" options={options} /> */}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={12} md={4} lg={4}>
                {/* <CommonSelect label="検索" options={options} /> */}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  font: 20,
                }}
              >
                <Typography variant="h5">～</Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                <Button variant="contained">
                  <SearchIcon />
                  検索
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ mt: 3 }}>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={12} md={4} lg={4}>
                {/* <CommonSelect label="更新" options={options} /> */}
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <TextField label="Previous Value Here" fullWidth size="small" />
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  font: 20,
                }}
              >
                <Typography variant="h5">=</Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <TextField label="Updated Value Here" fullWidth size="small" />
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                <Button variant="contained">更新</Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        {/* <Box
          sx={{
            mt: 3,
          }}
        >
          <DataTable />
        </Box> */}
      </Box>
    </>
  )
}
