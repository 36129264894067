import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Print } from '../../../api/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

const tableStyle = {
  tableLayout: 'fixed',
  width: '100%',
  borderCollapse: 'collapse',
  border: '1px solid #cccfcd',
  '@media print': {
    width: '100%',
    height: '100%',
    pageBreakAfter: 'always',
    margin: 0,
  },
}
const columnHeaderStyles = {
  borderCollapse: 'collapse',
  border: '1px solid #cccfcd',
  fontWeight: 'bold',
  textAlign: 'center',
  width: '100%',
  padding: 1,
}
const rowHeaderStyles = {
  borderCollapse: 'collapse',
  border: '1px solid #cccfcd',
  fontWeight: 'bold',
  width: '19%',
  padding: 1,
}
const columnStyles = {
  borderCollapse: 'collapse',
  border: '1px solid #cccfcd',
  width: '81%',
  padding: 1,
}

const imgBoxStyles = {
  display: 'flex',
  // objectFit: 'cover',
  maxWidth: '99.9%',
  maxHeight: '99.9%',
  margin: '2px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  oveflow: 'hidden',
  cursor: 'pointer',
}
const imageBoxImageClick = {
  ...imgBoxStyles,
  width: '100vh',
  height: 'auto',
}

const descriptionFontsize = {
  fontSize: '14px',
}

export default function IndividualProduct({
  productId,
}: {
  productId: string
}) {
  const { data } = useQuery<Print>({ queryKey: ['getOne', 'print', productId] })
  const [enlarged, setEnlarged] = useState(false)
  const handleImageClick = () => {
    setEnlarged(!enlarged)
  }
  const { t } = useTranslation()

  const rowsAbove = [
    {
      condition: data?.print_consignor_en,
      label: t('masters.consignors.fields.english_name'),
      value: data?.consignor?.english_name,
    },
    {
      condition: data?.print_consignor_jp,
      label: t('masters.consignors.fields.japanese_name'),
      value: data?.consignor?.japanese_name,
    },
    {
      condition: data?.print_shipper,
      label: t('products.fields.shipper'),
      value: data?.shipper?.name,
    },
    {
      condition: data?.print_jan_code,
      label: t('products.fields.jan_code'),
      value: data?.jan_code,
    },
    {
      condition: data?.print_product_no,
      label: t('products.fields.product_no'),
      value: data?.product_no,
    },
    {
      condition: data?.print_product_name,
      label: t('products.fields.product_name'),
      value: data?.product_name,
    },
    {
      condition: data?.print_material,
      label: t('products.fields.material'),
      value: data?.material,
    },
    {
      condition: data?.print_usage,
      label: t('products.fields.usage'),
      value: data?.usage,
    },
    {
      condition: data?.print_country_of_origin,
      label: t('products.fields.country_of_origin'),
      value: data?.country_of_origin?.name,
    },
  ]

  const otherRegulations = [
    data?.print_other_regulation1 ? data?.other_regulation1?.name : '',
    data?.print_other_regulation2 ? data?.other_regulation2?.name : '',
    data?.print_other_regulation3 ? data?.other_regulation3?.name : '',
  ]

  const rowsBelow = [
    {
      condition: data?.print_memo01,
      label: t('products.fields.memo01'),
      value: data?.memo01,
    },
    {
      condition: data?.print_memo02,
      label: t('products.fields.memo02'),
      value: data?.memo02,
    },
    {
      condition: data?.print_memo03,
      label: t('products.fields.memo03'),
      value: data?.memo03,
    },
    {
      condition: data?.print_memo04,
      label: t('products.fields.memo04'),
      value: data?.memo04,
    },
    {
      condition: data?.print_memo05,
      label: t('products.fields.memo05'),
      value: data?.memo05,
    },
    {
      condition: data?.print_memo06,
      label: t('products.fields.memo06'),
      value: data?.memo06,
    },
    {
      condition: data?.print_memo07,
      label: t('products.fields.memo07'),
      value: data?.memo07,
    },
    {
      condition: data?.print_memo08,
      label: t('products.fields.memo08'),
      value: data?.memo08,
    },
    {
      condition: data?.print_memo09,
      label: t('products.fields.memo09'),
      value: data?.memo09,
    },
    {
      condition: data?.print_memo10,
      label: t('products.fields.memo10'),
      value: data?.memo10,
    },
    {
      condition: data?.print_hs_code,
      label: t('products.fields.hs_code'),
      value: data?.hs_code,
    },
    {
      condition: data?.print_tariff_rate,
      label: t('products.fields.tariff_rate'),
      value: data?.tariff_rate,
    },
    {
      condition: data?.print_certificate_of_origin,
      label: t('products.fields.certificate_of_origin'),
      value: data?.certificate_of_origin,
    },
  ]

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography sx={descriptionFontsize}>
            {[
              { label: 'ID:', value: data?.id },
              { label: '商品NO：', value: data?.product_no },
              { label: '品名：', value: data?.product_name },
            ].map((item, index) => (
              <React.Fragment key={index}>
                <span style={{ display: 'inline' }}>{item.label}</span>
                <span
                  style={{ display: 'inline', marginRight: 10, marginLeft: 3 }}
                >
                  {item.value}
                </span>
              </React.Fragment>
            ))}
          </Typography>
        </Grid>
      </Grid>

      <Table sx={tableStyle}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} sx={columnHeaderStyles}>
              商品説明
            </TableCell>
          </TableRow>
          {rowsAbove.map(
            (row, index) =>
              row.condition && (
                <TableRow key={index}>
                  <TableCell sx={rowHeaderStyles}>{row.label}</TableCell>
                  <TableCell colSpan={3} sx={columnStyles}>
                    {row.value}
                  </TableCell>
                </TableRow>
              )
          )}
          {(data?.print_other_regulation1 ||
            data?.print_other_regulation2 ||
            data?.print_other_regulation3) && (
            <TableRow>
              <TableCell sx={rowHeaderStyles}>他法令</TableCell>
              {otherRegulations.map((regulation, index) => (
                <TableCell key={index} sx={columnStyles}>
                  {regulation}
                </TableCell>
              ))}
            </TableRow>
          )}
          {rowsBelow.map(
            (row, index) =>
              row.condition && (
                <TableRow
                  key={index + rowsAbove.length + otherRegulations.length}
                >
                  <TableCell sx={rowHeaderStyles}>{row.label}</TableCell>
                  <TableCell colSpan={3} sx={columnStyles}>
                    {row.value}
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>

      {data?.print_image &&
        data?.product_details !== undefined &&
        data?.product_details?.length > 0 &&
        data?.product_details?.map((item) => (
          <Box
            key={item.id}
            sx={{
              '@media print': {
                width: '100%',
                height: '100%',
                pageBreakBefore: 'always',
                pageBreakAfter: 'always',
                margin: 0,
              },
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              <Grid item xs={12}>
                <Typography sx={descriptionFontsize}>
                  {[
                    { label: '商品NO：', value: data?.product_no },
                    { label: '品名：', value: data?.product_name },
                  ].map((item, index) => (
                    <React.Fragment key={index}>
                      <span style={{ display: 'inline' }}>{item.label}</span>
                      <span
                        style={{
                          display: 'inline',
                          marginRight: 10,
                          marginLeft: 3,
                        }}
                      >
                        {item.value}
                      </span>
                    </React.Fragment>
                  ))}
                </Typography>
              </Grid>
            </Grid>
            <Table sx={tableStyle}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={4} sx={columnHeaderStyles}>
                    画像イメージ
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box
                      component="img"
                      sx={{
                        ...(enlarged ? imageBoxImageClick : imgBoxStyles),
                        '@media print': imgBoxStyles,
                      }}
                      src={`${item.image_path}`}
                      alt={item.image_path}
                      loading="lazy"
                      onClick={handleImageClick}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ))}
    </>
  )
}
