import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { FieldValues, useForm } from 'react-hook-form'

import { ListQueryResult } from '../../api/types'

export default function useSearch<T extends FieldValues, U>(
  resource: string,
  filterDefault: {
    [key: string]: string | number | boolean
  } | null = null,
  resolver: any = undefined
) {
  const [searchParams, setSearchParams] = useSearchParams()

  const searchParamObject: any = useMemo(
    () => ({ page_size: 30, ...Object.fromEntries(searchParams) }),
    [searchParams]
  )
  const { page = 1, ...filterValues } = searchParamObject

  const query = useQuery<ListQueryResult<U>>({
    queryKey: ['getList', resource, searchParamObject],
    enabled: searchParams.size > 0,
  })
  const form = useForm<T>({
    defaultValues: filterDefault
      ? { ...filterDefault, ...filterValues }
      : (filterValues as any),
    resolver,
  })

  const handleSubmit = form.handleSubmit((filters) => {
    setSearchParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams)
      Object.entries(filters).forEach(([key, value]) => {
        newParams.set('page', '1')
        if (value) {
          newParams.set(key, value)
        } else {
          newParams.delete(key)
        }
      })
      return newParams
    })
    query.refetch()
  })

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setSearchParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams)
      newParams.set('page', newPage.toString())
      return newParams
    })
  }

  return {
    query,
    form,
    handleSubmit,
    page,
    handlePageChange,
    filterValues,
  }
}
