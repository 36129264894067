import React, { useEffect, useState } from 'react'
import { makeStyles, styled, useTheme } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import AccountCircle from '@mui/icons-material/AccountCircle'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import CssBaseline from '@mui/material/CssBaseline'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Tooltip from '@mui/material/Tooltip'

import { NavLink, Outlet } from 'react-router-dom'
import { useAPI } from '../../api'
import { useMutation } from '@tanstack/react-query'
import { ListItemText } from '@mui/material'
import sideMenuItems from './SideMenu'
import useDrawerStore from '../../store/drawerStore'
import fetch from '../../api/axios'
import useAuthStore from '../../store/authStore'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${drawerWidth}px`,
  ...(!open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  '@media print': {
    display: 'none', // Hide the button when printing
  },
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  '@media print': {
    display: 'none',
  },
}))

const Frame: React.FC = () => {
  const api = useAPI()
  const [diskInfo, setDiskInfo] = useState({
    total: null,
    used: null,
    free: null,
  })
  const { user, superUser, referenceUser } = useAuthStore()

  useEffect(() => {
    const diskSpace = async () => {
      try {
        if (superUser) {
          const res: any = await fetch.get('/disk-info/')
          const { total, used, free } = res.data
          setDiskInfo({ total, used, free })
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (superUser) {
      diskSpace()
    }
  }, [superUser])
  const { open, closeDrawer, toggleDrawer } = useDrawerStore()

  const logout = useMutation({
    mutationFn: () => api.logout(),
  })

  const theme = useTheme()
  // const [open, setOpen] = React.useState(false)
  // const handleDrawerOpen = () => {
  //   setOpen(true)
  // }
  // const handleDrawerClose = () => {
  //   setOpen(false)
  // }

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            商品管理システム
          </Typography>
          {superUser && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            >
              {`【ディスク】 全体： ${diskInfo.total}  MB / 使用： ${diskInfo.used} MB /
              空き： ${diskInfo.free} MB`}
            </Typography>
          )}
          <div>
            <Tooltip title="Open settings">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Typography>{`${user?.first_name} ${user?.last_name}`}</Typography>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={'logout'}
                onClick={() => {
                  logout.mutate()
                  handleCloseUserMenu()
                }}
                disabled={logout.isPending}
              >
                <Typography textAlign="center">{'ログアウト'}</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          sx={{
            width: drawerWidth,
            '@media print': {
              display: 'none', // Hide the button when printing
            },
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={closeDrawer}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            sx={{
              '& a.active': {
                backgroundColor: 'action.selected',
              },
            }}
          >
            {sideMenuItems.map(
              ({
                key,
                to,
                label,
                superUserDisplay,
                referenceUserDisplay,
                newWindow,
              }) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    display:
                      superUser ||
                      (referenceUser && referenceUserDisplay !== false) ||
                      (!superUser &&
                        !referenceUser &&
                        superUserDisplay !== true)
                        ? 'block'
                        : 'none',
                  }}
                >
                  <ListItemButton
                    component={NavLink}
                    to={to}
                    target={newWindow ? '_blank' : undefined}
                  >
                    <ListItemText primary={label} />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Drawer>
      </nav>
      <Main open={open}>
        <DrawerHeader />
        <div>
          <Outlet />
        </div>
      </Main>
    </div>
  )
}

export default Frame
