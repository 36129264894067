import React, { useEffect } from 'react'
import useTokenStore from '../../store/tokenStore'

import { useQuery } from '@tanstack/react-query'
import { Navigate } from 'react-router-dom'
import { Backdrop, CircularProgress } from '@mui/material'
import useAuthStore from '../../store/authStore'

const AuthGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const token = useTokenStore((state) => state.token)
  const setAuthUser = useAuthStore((state: any) => state.setUser)

  const { data, isLoading }: { data: any; isLoading: boolean } = useQuery({
    queryKey: ['getUser'],
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false,
  })
  useEffect(() => {
    if (token) {
      // Set the user in the authentication store when the token is present and user is fetched
      setAuthUser(data?.data)
    }
  }, [token, data, setAuthUser])
  if (!token) {
    return <Navigate to="/login" />
  }
  return (
    <>
      {isLoading && (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {children}
    </>
  )
}

export default AuthGuard
