import { Button } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { debounce } from 'lodash'
import useDrawerStore from '../../../store/drawerStore'
import IndividualProduct from './IndividualProduct'

export default function PrintProduct() {
  const [searchParams] = useSearchParams()
  const productIds = searchParams.getAll('id')
  const { closeDrawer } = useDrawerStore()
  const { t } = useTranslation()
  // ToDo: Delay the printFunction so that Drawer is closed first before printing
  const printDebounced = debounce(() => {
    window.print()
  }, 100)

  const printScreen = () => {
    closeDrawer()
    printDebounced()
  }

  if (productIds.length === 0) {
    return <></> // No product IDs, return nothing
  }

  return (
    <>
      <Button
        variant="contained"
        startIcon={<PrintIcon />}
        onClick={printScreen}
        sx={{
          '@media print': {
            display: 'none', // Hide the button when printing
          },
        }}
      >
        {t('actions.print')}
      </Button>

      {productIds.map((productId: string, index: number) => (
        <IndividualProduct key={index} productId={productId} />
      ))}
    </>
  )
}
