import { filter } from 'lodash'
import i18n from '../../i18n'
export interface SideMenuItem {
  key: string
  to: string
  label: string
  superUserDisplay?: boolean
  referenceUserDisplay?: boolean
  newWindow?: boolean
}

const sideMenuItems: SideMenuItem[] = [
  { key: 'search', to: '/search', label: i18n.t('menus.search') },
  {
    key: 'batch',
    to: '/batch',
    label: i18n.t('menus.batch'),
    referenceUserDisplay: false,
  },
  {
    key: 'register',
    to: '/register',
    label: i18n.t('menus.register'),
    referenceUserDisplay: false,
  },
  {
    key: 'invoice',
    to: '/invoice',
    label: i18n.t('menus.invoice'),
    referenceUserDisplay: false,
  },
  {
    key: 'naccs',
    to: '/naccs',
    label: i18n.t('menus.naccs'),
    referenceUserDisplay: false,
  },
  {
    key: 'userMaster',
    to: '/master_maintenance/users',
    label: i18n.t('menus.userMaster'),
    superUserDisplay: true,
    referenceUserDisplay: false,
    newWindow: true,
  },
  {
    key: 'consignor',
    to: '/masters/consignors',
    label: i18n.t('menus.consignor'),
    referenceUserDisplay: false,
  },
  {
    key: 'shipper',
    to: '/masters/shippers',
    label: i18n.t('menus.shipper'),
    referenceUserDisplay: false,
  },
  {
    key: 'countries',
    to: '/masters/countries',
    label: i18n.t('menus.countries'),
    superUserDisplay: true,
    referenceUserDisplay: false,
  },
  {
    key: 'regulations',
    to: '/masters/regulations',
    label: i18n.t('menus.regulations'),
    superUserDisplay: true,
    referenceUserDisplay: false,
  },
]

export default sideMenuItems
