import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { repeat } from 'lodash'

interface SearchInputProps {
  group: number
}

const SEARCH_FIELD_CHOICES = [
  'consignor__english_name',
  'consignor__japanese_name',
  'shipper__name',
  'jan_code',
  'product_no',
  'product_name',
  'material',
  'usage',
  'country_of_origin__name',
  'other_regulation1__name',
  'other_regulation2__name',
  'other_regulation3__name',
  'hs_code',
  'tariff_rate',
  'memo01',
  'memo02',
  'memo03',
  'memo04',
  'memo05',
  'memo06',
  'memo07',
  'memo08',
  'memo09',
  'memo10',
  'certificate_of_origin',
  'certificate_status',
]

const CERTIFICATE_STATUS_CHOICES = ['1', '0', '']

export default function SearchInput({ group }: SearchInputProps) {
  const { t } = useTranslation()

  const filter = useWatch({ name: `filter${group}` })

  const { resetField } = useFormContext()

  return (
    <Grid container item spacing={2}>
      <Grid item xs={3.5}>
        <Controller
          name={`filter${group}`}
          render={({ field: { onChange, ...restProps }, fieldState }) => (
            <FormControl fullWidth>
              <Select
                {...restProps}
                onChange={(e) => {
                  onChange(e)
                  resetField(`from${group}`, {
                    defaultValue: '',
                  })
                  resetField(`to${group}`, {
                    defaultValue: '',
                  })
                }}
                error={fieldState.invalid}
                size="small"
                displayEmpty
              >
                <MenuItem value="">{repeat('-', 10)}</MenuItem>
                {SEARCH_FIELD_CHOICES.map((choice) => (
                  <MenuItem key={choice} value={choice}>
                    {t(`products.filters.${choice}`)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      {filter === 'certificate_status' ? (
        <Grid item xs={4}>
          <Controller
            name={`from${group}`}
            render={({ field, fieldState }) => (
              <FormControl fullWidth>
                <Select
                  {...field}
                  size="small"
                  displayEmpty
                  error={fieldState.invalid}
                >
                  {CERTIFICATE_STATUS_CHOICES.map((choice) => (
                    <MenuItem key={choice} value={choice}>
                      {t(
                        `products.fields.certificate_status_choices.${choice}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {fieldState.error?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={4}>
            <Controller
              name={`from${group}`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={0.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" justifySelf="stretch">
              ～
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`to${group}`}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}
