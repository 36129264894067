import _ from 'lodash'
import dayjs from 'dayjs'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Navigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Collapse,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'

import { useAPI } from '../../../api'
import {
  ListQueryResult,
  Master,
  MasterAppendPayload,
} from '../../../api/types'
import { MasterFieldConfig } from '../types'
import useAuthStore from '../../../store/authStore'

export interface MasterAppendProps {
  resource: string
  fields: MasterFieldConfig[]
}

export default function MasterAppendCommon({
  resource,
  fields,
}: MasterAppendProps) {
  const { t } = useTranslation()
  const [showMessage, setShowMessage] = useState(false)
  const { code } = useParams()
  const api = useAPI()

  const [searchParams, setSearchParams] = useSearchParams()
  const page =
    searchParams.get('page') !== null ? Number(searchParams.get('page')) : 1
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setSearchParams({ page: value.toString() })
  }
  const { superUser, referenceUser } = useAuthStore()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<MasterAppendPayload>({
    defaultValues: {
      apply_date: dayjs().format('YYYY-MM-DD'),
    },
  })

  const { data, refetch } = useQuery<ListQueryResult<Master>>({
    queryKey: [
      'getList',
      resource,
      { code, ordering: '-apply_date', page_size: 30, page },
    ],
  })

  const appendMutation = useMutation({
    mutationFn: (data: MasterAppendPayload) =>
      api.create<Master>(resource, { ...data, code }),
    onSuccess: () => {
      reset()
      refetch()
    },
  })

  const deleteMutation = useMutation({
    mutationFn: (id: number) => api.delete(resource, id),
    onSuccess: () => {
      refetch()
      setShowMessage(true)
    },
  })

  const validateApplyDate = (value: string) => {
    const existingDates = data?.results.map((item) => item.apply_date)
    console.log('existingDates', existingDates)

    if (existingDates?.includes(value)) {
      return t('validations.uniqueApplyDate') // Adjust the error message accordingly
    }

    return true
  }
  if (!(superUser || !referenceUser)) {
    return <Navigate to="/" />
  }

  return (
    <form onSubmit={handleSubmit((data) => appendMutation.mutate(data))}>
      <Stack spacing={2}>
        <Typography>
          {t(`masters.${resource}.name`) + t('masters.common.title.detail')}
        </Typography>
        <Stack direction="row-reverse">
          <Button
            name="append"
            color="primary"
            variant="contained"
            type="submit"
            disabled={appendMutation.isPending}
            startIcon={<AddIcon />}
          >
            <Typography noWrap>{t('actions.create')}</Typography>
          </Button>
        </Stack>
        <Paper>
          <Stack spacing={2} p={2}>
            <TextField
              label={t('masters.common.fields.code')}
              variant="outlined"
              size="small"
              fullWidth
              value={code}
              disabled
            />
            {fields.map(({ name }) => (
              <TextField
                key={name}
                label={t(`masters.${resource}.fields.${name}`)}
                variant="outlined"
                size="small"
                fullWidth
                error={Boolean(errors?.[name])}
                helperText={errors?.[name]?.message}
                {...register(name, {
                  required: t('validations.required', {
                    field: t(`masters.${resource}.fields.${name}`),
                  }),
                })}
              />
            ))}
            <TextField
              label={t('masters.common.fields.apply_date')}
              type="date"
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors?.apply_date)}
              helperText={errors?.apply_date?.message}
              {...register('apply_date', {
                required: t('validations.required', {
                  field: t('masters.common.fields.apply_date'),
                }),
                validate: validateApplyDate,
              })}
            />
          </Stack>
        </Paper>
        {/* when delete finished, show a message here */}
        <Collapse in={showMessage}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowMessage(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {t('messages.success', {
              action: t('actions.delete'),
            })}
          </Alert>
        </Collapse>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: _.ceil(100 / (2 + fields.length), 0) + '%',
                      textAlign: 'left',
                    }}
                    colSpan={2}
                  >
                    {t(`masters.common.fields.code`)}
                  </TableCell>
                  {fields.map(({ name }) => (
                    <TableCell
                      key={name}
                      sx={{
                        width: _.ceil(100 / (2 + fields.length), 0) + '%',
                        textAlign: 'left',
                      }}
                    >
                      {t(`masters.${resource}.fields.${name}`)}
                    </TableCell>
                  ))}
                  <TableCell
                    sx={{
                      width: _.ceil(100 / (2 + fields.length), 0) + '%',
                      textAlign: 'left',
                    }}
                  >
                    {t(`masters.common.fields.apply_date`)}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  // change background color of odd table rows
                  '& > :nth-of-type(odd)': {
                    bgcolor: 'action.hover',
                  },
                }}
              >
                {data?.results?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.code}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          deleteMutation.mutate(item.id)
                        }}
                        disabled={deleteMutation.isPending}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    {fields.map(({ name }) => (
                      <TableCell key={name} align="left">
                        {item[name]}
                      </TableCell>
                    ))}
                    <TableCell align="left">{item.apply_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Stack direction="row" justifyContent="center">
          <Pagination
            count={data ? _.ceil(data.count / data.results.length) : 0}
            page={Number(page)}
            onChange={handlePageChange}
          />
        </Stack>
      </Stack>
    </form>
  )
}
