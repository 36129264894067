import _ from 'lodash'

import { useRef } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button, ButtonGroup, Checkbox, InputAdornment } from '@mui/material'
import { MuiFileInput } from 'mui-file-input'

import { InputProps } from '../types'
import { useInitalData } from '../InitialDataContext'

export default function FileInput({
  name,
  label,
  check = true,
  printName,
}: InputProps) {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const initialData = useInitalData()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <MuiFileInput
          label={label}
          fullWidth
          {...field}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          inputRef={inputRef}
          inputProps={{
            accept: 'image/*',
          }}
          placeholder={t('messages.selectFile')}
          value={
            typeof field.value === 'string'
              ? new File([], field.value.split('/').pop() || '')
              : field.value
          }
          hideSizeText
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Controller
                  control={control}
                  disabled={!check}
                  name={printName ?? `print_${name}`}
                  render={({ field: { value, ...fieldProps } }) => (
                    <Checkbox {...fieldProps} checked={value} />
                  )}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      inputRef.current?.click()
                    }}
                  >
                    {t('actions.select')}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      field.onChange(null)
                    }}
                    disabled={!field.value}
                  >
                    {t('actions.delete')}
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      // open new browser tab to show image in initialData
                      const url = _.get(initialData, name) as string
                      window.open(url, '_blank')
                    }}
                    disabled={!_.get(initialData, name)}
                  >
                    {t('actions.showPrevious')}
                  </Button>
                </ButtonGroup>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
