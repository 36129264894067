import toFormData from 'json-form-data'

import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { useTranslation } from 'react-i18next'

import { Card, CardContent, CardHeader } from '@mui/material'

import { Product } from '../../../api/types'
import { useAPI } from '../../../api'
import ProductForm from '../ProductForm'
import { ProductFormValues } from '../ProductForm/types'

export default function Update() {
  const { t } = useTranslation()
  const api = useAPI()
  const { id } = useParams()

  const { data } = useQuery<Product>({ queryKey: ['getOne', 'products', id] })

  const onSubmit = useCallback(
    (formData: ProductFormValues) => {
      return api.update(
        'products',
        id!,
        toFormData({ ...formData, version: data!.version }),
        true
      )
    },
    [api, id, data]
  )

  return (
    <Card>
      <CardHeader title={t(`products.titles.update`)} />
      <CardContent>
        <ProductForm action="update" onSubmit={onSubmit} initialData={data} />
      </CardContent>
    </Card>
  )
}
