import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Alert,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import Stack from '@mui/system/Stack'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAPI } from '../../api'
import MasterSelect from './MasterSelect'
import { Product } from '../../api/types'
import { useTranslation } from 'react-i18next'
import {
  textColumn,
  keyColumn,
  Column,
  floatColumn,
  createAddRowsComponent,
  createContextMenuComponent,
  ContextMenuItem,
  checkboxColumn,
  DynamicDataSheetGrid,
} from 'react-datasheet-grid'
import { FormProvider, useForm } from 'react-hook-form'
import i18n from '../../i18n'
import { useSnackbar } from 'notistack'
import { Filter, InvoicePayload, InvoiceRow, SheetError } from './types'
import productSelectColumn from './productColumn'
import { flatMap, isEmpty } from 'lodash'
import './index.css'
import useWindowDimensions from '../../customHook/useWindowDimensions'
import { TemlateListSearch } from '../PrintScreen/PrintList/TemplateListSearch'
import usePrintStore from '../../store/printStore'

const FORM_DIV_HEIGHT = 200
const EXTRA_BOTTOM_MARGIN = 200
const DATAGRID_ROW_HEIGHT = 35
const filterSchema = yup.object().shape(
  {
    consignor: yup.string().when('shipper', {
      is: (shipper: string) => !shipper,
      then: () =>
        yup.string().required(
          i18n.t('validations.atLeastOne', {
            field: `${i18n.t('invoices.filters.consignor')}/${i18n.t(
              'invoices.filters.shipper'
            )}`,
          })
        ),
    }),
    shipper: yup.string().when('consignor', {
      is: (consignor: string) => !consignor,
      then: () =>
        yup.string().required(
          i18n.t('validations.atLeastOne', {
            field: `${i18n.t('invoices.filters.consignor')}/${i18n.t(
              'invoices.filters.shipper'
            )}`,
          })
        ),
    }),
  },
  [['consignor', 'shipper']]
)

const sheetSchema = yup
  .array()
  .of(
    yup.object().shape({
      checked: yup.boolean().default(false),
      product: yup
        .mixed()
        .test((value) => typeof value === 'string' || typeof value === 'number')
        .required(
          i18n.t('validations.required', {
            field: i18n.t('invoices.fields.product'),
          })
        ),
      quantity: yup.number().required(
        i18n.t('validations.required', {
          field: i18n.t('invoices.fields.quantity'),
        })
      ),
      unit_price: yup.number().required(
        i18n.t('validations.required', {
          field: i18n.t('invoices.fields.unit_price'),
        })
      ),
      total_price: yup.number().required(
        i18n.t('validations.required', {
          field: i18n.t('invoices.fields.total_price'),
        })
      ),
      material: yup.string().when('checked', {
        is: true,
        then: () =>
          yup.string().required(
            i18n.t('validations.requiredIfSelected', {
              field: i18n.t('invoices.fields.material'),
            })
          ),
        otherwise: () => yup.string().nullable(),
      }),
      jan_code: yup.string().when('checked', {
        is: true,
        then: () =>
          yup.string().required(
            i18n.t('validations.requiredIfSelected', {
              field: i18n.t('invoices.fields.jan_code'),
            })
          ),
        otherwise: () => yup.string().nullable(),
      }),
      hs_code: yup.string().when('checked', {
        is: true,
        then: () =>
          yup.string().required(
            i18n.t('validations.requiredIfSelected', {
              field: i18n.t('invoices.fields.hs_code'),
            })
          ),
        otherwise: () => yup.string().nullable(),
      }),
    })
  )
  .required()
  // .test({
  //   name: 'noDuplicateProduct',
  //   message: i18n.t('validations.noDuplicateProduct'),
  //   test: function (value) {
  //     const products = value.map((v) => v.product).filter((v) => !isNil(v))
  //     if (products.length !== new Set(products).size) {
  //       // find duplicated product index
  //       const duplicatedIndex: number[] = []
  //       products.forEach((p, i) => {
  //         if (products.indexOf(p) !== i && duplicatedIndex.indexOf(p) === -1) {
  //           duplicatedIndex.push(i)
  //         }
  //       })
  //       return this.createError({
  //         type: 'rowError',
  //         path: `${duplicatedIndex.join(',')}`,
  //         message: i18n.t('validations.noDuplicate', {
  //           field: i18n.t('invoices.fields.product'),
  //         }),
  //       })
  //     }
  //     return true
  //   },
  // })
  .transform((value) =>
    value.map((v: InvoiceRow) => ({
      ...v,
      product: v.product
        ? v.product.id !== undefined
          ? v.product.id
          : v.product.product_name
        : null,
    }))
  )

function sumOfTotalCost(array: InvoiceRow[]) {
  let sum = 0
  array.forEach((obj) => {
    if (obj.total_price) {
      sum += obj.total_price
    }
  })
  return sum.toLocaleString()
}

export default function Invoice() {
  const api = useAPI()
  const { t } = useTranslation()
  const [filter, setFilter] = useState<Filter | null>(null)
  const [errors, setErrors] = useState<SheetError[]>([])
  const [templateSelected, setTemplateSelected] = useState(false)
  const [selectedTemplateName, setSelectedTemplateName] = useState('')
  const [totalAmountSum, setTotalAmountSum] = useState<string | number>('')
  const [format, setFormat] = useState<string>('En')
  const { products } = usePrintStore()
  const cellClassName = useCallback(
    ({
      columnId,
      rowIndex,
    }: {
      rowData: InvoiceRow
      rowIndex: number
      columnId?: string | undefined
    }) =>
      errors.some((e) => e.row === rowIndex && e.column === columnId)
        ? 'cell-error'
        : undefined,
    [errors]
  )

  const rowClassName = useCallback(
    ({ rowIndex }: { rowIndex: number }) =>
      errors.some((e) => e.row === rowIndex && !e.column)
        ? 'row-error'
        : undefined,
    [errors]
  )
  const handleFormatChange = (e: SelectChangeEvent) => {
    setFormat(e.target.value as string)
  }

  const columns: Column<InvoiceRow>[] = useMemo(
    () => [
      {
        ...keyColumn('product', productSelectColumn({ filter: filter! })),
        title: t('invoices.fields.product'),
        basis: 280,
        cellClassName,
      },
      {
        ...keyColumn('unit_price', floatColumn),
        title: t('invoices.fields.unit_price'),
        basis: 140,
        cellClassName,
      },
      {
        ...keyColumn('quantity', floatColumn),
        title: t('invoices.fields.quantity'),
        basis: 140,
        cellClassName,
      },
      {
        ...keyColumn('total_price', floatColumn),
        title: `${t('invoices.fields.total_price')} ${totalAmountSum}`,
        basis: 140,
        cellClassName,
      },
      {
        ...keyColumn('material', textColumn),
        title: t('invoices.fields.material'),
        basis: 140,
        cellClassName,
      },
      {
        ...keyColumn('jan_code', textColumn),
        title: t('invoices.fields.jan_code'),
        basis: 140,
        cellClassName,
      },
      {
        ...keyColumn('hs_code', textColumn),
        title: t('invoices.fields.hs_code'),
        basis: 210,
        cellClassName,
      },
      {
        ...keyColumn('checked', checkboxColumn),
        title: t('invoices.fields.checked'),
        basis: 70,
        grow: 0,
        shrink: 0,
        cellClassName,
      },
    ],
    [t, filter, cellClassName, totalAmountSum]
  )

  const AddRows = useMemo(
    () =>
      createAddRowsComponent({
        button: t('actions.add'),
        unit: t('preferences.units.row'),
      }),
    [t]
  )

  const ContextMenu = useMemo(
    () =>
      createContextMenuComponent(((item: ContextMenuItem) => {
        switch (item.type) {
          case 'CUT':
            return t('actions.cut')
          case 'COPY':
            return t('actions.copy')
          case 'PASTE':
            return t('actions.paste')
          case 'DELETE_ROW':
          case 'DELETE_ROWS':
            return t('actions.deleteRow')
          case 'DUPLICATE_ROW':
          case 'DUPLICATE_ROWS':
            return t('actions.duplicateRow')
          case 'INSERT_ROW_BELLOW':
            return t('actions.insertRowBelow')
        }
      }) as any),
    [t]
  )
  const fetchProductsInTemplateMutation = useMutation({
    mutationFn: () =>
      api.postWithAction('print-template', 'products-in-template', {
        ids: products,
      }) as Promise<Product[]>,
    onSuccess: (data) => {
      // sort the data in the same order as its stored in Store
      const dataMap = new Map(data.map((item, index) => [item.id, item]))
      const sortedData = products
        .map((id) => dataMap.get(id))
        .filter(Boolean) as Product[]
      // then update the Rows
      const updateProductRows = () => {
        const updatedItems: InvoiceRow[] = sortedData.map(
          (item: Product, index: number) => {
            const updatedItem: InvoiceRow = {
              checked: false,
              material: item.material ?? null,
              jan_code: item.jan_code ?? null,
              hs_code: item.hs_code ?? null,
              product: item,
              quantity: null, // Example value, replace with actual value
              unit_price: null, // Example value, replace with actual value
              total_price: null, // Example value, replace with actual value
            }
            return updatedItem
          }
        )
        setData(updatedItems) // Assuming setData is a state update function
      }

      updateProductRows() // Call the function to update product rows
    },
  })

  useEffect(() => {
    if (filter && templateSelected) {
      setTotalAmountSum('')
      fetchProductsInTemplateMutation.mutate()
    }
  }, [filter, products, templateSelected])
  const handleCellChange = (values: InvoiceRow[]) => {
    const updatedData = values.map((item, index) => {
      const previousItem = previousData ? previousData[index] : null

      // Check if the "product" and "hs_code" field has changed
      const productChanged =
        previousItem && item.product !== previousItem.product

      const updatedItem = {
        quantity: item.quantity,
        unit_price: item.unit_price,
        total_price:
          item.unit_price && item.quantity
            ? item.unit_price * item.quantity
            : item.total_price
            ? item.total_price
            : null,
        checked: item.checked,
        product: item.product,
        // Set "hs_code", material, jan_code only if the "product" field has changed
        material: productChanged
          ? (item.product?.material as string)
          : item.material ?? null,
        jan_code: productChanged
          ? (item.product?.jan_code as string)
          : item.jan_code ?? null,
        hs_code: productChanged
          ? (item.product?.hs_code as string)
          : item.hs_code ?? null,
      }
      return updatedItem
    })
    setTotalAmountSum(sumOfTotalCost(updatedData))
    setData(updatedData)
    setErrors([])

    // Update the previousData for the next iteration
    setPreviousData([...values])
  }

  const { enqueueSnackbar } = useSnackbar()
  const initialRows: InvoiceRow[] = Array.from({ length: 20 }, () => ({
    checked: false,
    product: null,
    quantity: null,
    unit_price: null,
    total_price: null,
    material: null,
    jan_code: null,
    hs_code: null,
  }))

  // this function takes value from child component TemplateListSearch when the onChangeIs triggered
  const templateSelect = (
    isTemplateSelected: boolean,
    templateName: string
  ) => {
    setTemplateSelected(isTemplateSelected)
    setSelectedTemplateName(templateName)
  }
  const [data, setData] = useState<InvoiceRow[]>(initialRows)
  const [previousData, setPreviousData] = useState<InvoiceRow[]>(initialRows)
  const form = useForm<Filter>({
    resolver: yupResolver(filterSchema) as any,
    defaultValues: {
      consignor: '',
      shipper: '',
    },
  })

  const onSubmit = form.handleSubmit((data) => {
    setFilter(data)
  })

  const reset = () => {
    form.reset()
    setData(initialRows)
    setPreviousData(initialRows)
    setErrors([])
    setFilter(null)
    setTemplateSelected(false)
    setTotalAmountSum('')
  }
  const { height } = useWindowDimensions()
  const exportMutation = useMutation({
    mutationFn: (data: InvoicePayload[]) =>
      api.toFile('invoice', data, false, selectedTemplateName),
    onSuccess: () => {
      enqueueSnackbar(t('messages.success', { action: t('actions.export') }), {
        variant: 'success',
      })
    },
  })

  const exportHandler = async () => {
    try {
      // setErrors([])
      await sheetSchema.validate(data, { abortEarly: false })
      const payload = sheetSchema.cast(data)
      const payloadWithFilterData = [
        ...payload,
        { templateName: selectedTemplateName, format: format },
        filter,
      ]
      await exportMutation.mutateAsync(
        payloadWithFilterData as InvoicePayload[]
      )
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: SheetError[] = flatMap(error.inner, (e) => {
          if (e.type === 'rowError') {
            const row = e.path!.split(',').map((i) => parseInt(i))
            return row.map((r) => ({
              row: r,
              message: e.message,
            }))
          } else {
            // separate row and column from path like "[1].product"
            const [rowIndex, column] = e.path!.split('.')
            const row = parseInt(rowIndex.replace(/\D/g, ''))
            return {
              row,
              column,
              message: e.message,
            }
          }
        })
        setErrors(errors)
      }
    }
  }

  return (
    <Stack spacing={4}>
      <Typography variant="h6">{t('invoices.title')}</Typography>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <MasterSelect
                resource="consignors"
                getLabel={(c) => `${c.japanese_name} / ${c.english_name}`}
                name="consignor"
                width={600}
                height={FORM_DIV_HEIGHT}
              />
            </Grid>
            {/* <Grid item xs={5}>
              <MasterSelect
                resource="shippers"
                getLabel={(s) => s.name}
                name="shipper"
                width={600}
                height={FORM_DIV_HEIGHT}
              />
            </Grid> */}
            <Grid item xs={1} display="flex">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={form.formState.isSubmitSuccessful}
              >
                {t('actions.inputStart')}
              </Button>
            </Grid>
            <Grid item xs={1} display="flex">
              <Button
                fullWidth
                variant="contained"
                onClick={reset}
                disabled={!form.formState.isSubmitSuccessful}
              >
                {t('actions.clear')}
              </Button>
            </Grid>
            {form.formState.isSubmitSuccessful && (
              <Grid item xs={3} sx={{ ml: 'auto' }} display="flex">
                <TemlateListSearch
                  filter={filter}
                  onChangeIndicator={templateSelect}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      {form.formState.isSubmitSuccessful && (
        <>
          <DynamicDataSheetGrid
            value={data}
            columns={columns}
            onChange={handleCellChange}
            addRowsComponent={AddRows as any}
            contextMenuComponent={ContextMenu as any}
            rowHeight={DATAGRID_ROW_HEIGHT}
            rowClassName={rowClassName}
            height={height - FORM_DIV_HEIGHT - EXTRA_BOTTOM_MARGIN}
          />
          {!isEmpty(errors) && (
            <Stack>
              {errors.map((e) => (
                <Alert severity="error">
                  <span>{e.row + 1}行目：</span>
                  {e.message}
                </Alert>
              ))}
            </Stack>
          )}
          <Stack flexDirection="row-reverse" gap={1}>
            <Button
              variant="contained"
              disabled={exportMutation.isPending}
              onClick={exportHandler}
            >
              {t('actions.export')}
            </Button>
            <FormControl>
              <Select
                id="format-select"
                value={format}
                onChange={handleFormatChange}
              >
                <MenuItem value={'En'}>En</MenuItem>
                <MenuItem value={'JP'}>JP</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </>
      )}
    </Stack>
  )
}
