import { first, last } from 'lodash'

import {
  FocusEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useDebounce } from 'use-debounce'
import { CellProps, Column } from 'react-datasheet-grid'
import { useTranslation } from 'react-i18next'

import {
  Box,
  CircularProgress,
  ClickAwayListener,
  InputBase,
  Paper,
  Popper,
} from '@mui/material'
import {
  bindFocus,
  bindPopper,
  usePopupState,
} from 'material-ui-popup-state/hooks'

import { ListQueryResult, Product } from '../../api/types'
import { Filter } from './types'
import { useAPI } from '../../api'

interface ProductSelectOptions {
  filter: Filter
}

const ProductSelectComponent: any = memo(
  ({
    rowData,
    setRowData,
    focus,
    stopEditing,
    columnData,
  }: CellProps<Partial<Product> | null, ProductSelectOptions>) => {
    const { t } = useTranslation()
    const [focusIndex, setFocusIndex] = useState<number | null>(null)

    const parentRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const [inputValue, setInputValue] = useState('')
    const [debouncedInputValue] = useDebounce(inputValue, 500)

    const api = useAPI()
    const popupState = usePopupState({
      variant: 'popper',
      popupId: `product-select-popper`,
    })

    const fetchProduct = useCallback(
      ({ queryKey: [resource, params], pageParam }: any) =>
        api.getList<Partial<Product>>('invoice', {
          ...params,
          page: pageParam,
          page_size: 20,
        }),
      [api]
    )

    const { data, hasNextPage, isFetching, isFetchingNextPage, fetchNextPage } =
      useInfiniteQuery<ListQueryResult<Partial<Product>>>({
        queryKey: [
          'invoice',
          { ...columnData.filter, search: debouncedInputValue },
        ],
        queryFn: fetchProduct,
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
          return lastPage.next
        },
      })

    const allRows = useMemo(
      () => (data ? data.pages.flatMap((page) => page.results) : []),
      [data]
    )

    const rowVirtualizer = useVirtualizer({
      count: hasNextPage ? allRows.length + 1 : allRows.length,
      getScrollElement: () => parentRef.current,
      estimateSize: () => 50,
      overscan: 5,
    })

    const virturalItems = rowVirtualizer.getVirtualItems()
    useEffect(() => {
      if (popupState.isOpen) {
        rowVirtualizer.measure()
      }
    }, [popupState.isOpen, rowVirtualizer])
    useEffect(() => {
      const lastItem = last(virturalItems)
      if (!lastItem) {
        return
      }
      if (
        lastItem.index >= allRows.length - 1 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage()
      }
    }, [
      hasNextPage,
      fetchNextPage,
      allRows.length,
      isFetchingNextPage,
      virturalItems,
    ])

    const { onBlur: popupOnBlur, ...popupProps } = bindFocus(popupState)
    const onInputBlur = async (e: FocusEvent<Element, Element>) => {
      if (e.relatedTarget !== parentRef.current) {
        popupOnBlur(e)
      }
      setInputValue('')
    }

    useLayoutEffect(() => {
      if (focus) {
        inputRef.current?.focus()
        setInputValue('')
      } else {
        inputRef.current?.blur()
      }
    }, [focus])

    // set selected
    const confirmInput = useCallback(() => {
      if (focusIndex === null || inputValue.trim() !== '') {
        // If focusIndex is null (indicating a manually entered value) or inputValue is not empty
        // Create a new product object with the entered value
        const newProduct: Partial<Product> = {
          id: undefined,
          product_name: inputValue.trim(),
          product_no: '',
        }
        setRowData(newProduct)
      } else {
        // If focusIndex is not null (indicating a selected value), set rowData to the selected product
        const product = allRows[focusIndex]
        setRowData(product)
      }

      popupState.close()
      stopEditing({ nextRow: false })
    }, [setRowData, popupState, stopEditing, focusIndex, allRows])

    // key down
    const inputKeydownHandler = useCallback(
      (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const virturalItems = rowVirtualizer.getVirtualItems()
        // if is down arrow
        if (e.key === 'ArrowDown') {
          e.preventDefault()
          if (focusIndex === null) {
            const firstItem = first(virturalItems)
            if (firstItem) {
              setFocusIndex(firstItem.index)
            }
          } else {
            const nextIndex = focusIndex + 1
            if (nextIndex < allRows.length) {
              setFocusIndex(nextIndex)
              const item = virturalItems.find(
                (virtualItem) => virtualItem.index === nextIndex
              )
              if (
                item &&
                item.start + item.size >
                  parentRef.current!.clientHeight + parentRef.current!.scrollTop
              ) {
                rowVirtualizer.scrollToIndex(nextIndex)
              }
            }
          }
        } else if (e.key === 'ArrowUp') {
          e.preventDefault()
          if (focusIndex !== null) {
            const nextIndex = focusIndex - 1
            if (nextIndex >= 0) {
              setFocusIndex(nextIndex)
              const item = virturalItems.find(
                (virtualItem) => virtualItem.index === nextIndex
              )
              if (item && item.start < parentRef.current!.scrollTop) {
                rowVirtualizer.scrollToIndex(nextIndex)
              }
            }
          }
        } else if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
          confirmInput()
        }
      },
      [rowVirtualizer, focusIndex, allRows.length, confirmInput]
    )

    return (
      <ClickAwayListener onClickAway={popupState.close}>
        <>
          <InputBase
            fullWidth
            inputRef={inputRef}
            {...popupProps}
            value={
              focus
                ? inputValue
                : rowData
                ? rowData?.product_no
                  ? `${rowData?.product_no} ${rowData?.product_name}`
                  : `${rowData?.product_name}`
                : ''
            }
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={onInputBlur}
            sx={{
              pl: '10px',
            }}
            onKeyDown={inputKeydownHandler}
          />
          {focus && (
            <Popper
              {...bindPopper(popupState)}
              sx={{
                width: popupState.anchorEl?.clientWidth,
              }}
              placement="bottom"
            >
              <Paper sx={{ width: '100%', p: 1 }}>
                <Box
                  ref={parentRef}
                  tabIndex={-1}
                  sx={{
                    height: 300,
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      height: `${rowVirtualizer.getTotalSize()}px`,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    {isFetching ? (
                      <CircularProgress
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    ) : (
                      virturalItems.map((virtualRow) => {
                        const isLoaderRow =
                          virtualRow.index > allRows.length - 1
                        const product = allRows[virtualRow.index]
                        return (
                          <Box
                            key={virtualRow.index}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: `${virtualRow.size}px`,
                              transform: `translateY(${virtualRow.start}px)`,
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              backgroundColor:
                                focusIndex === virtualRow.index
                                  ? 'primary.light'
                                  : 'transparent',
                            }}
                            onClick={confirmInput}
                            onMouseEnter={() => {
                              setFocusIndex(virtualRow.index)
                            }}
                          >
                            {isLoaderRow
                              ? hasNextPage
                                ? t('messages.loading')
                                : null
                              : `${product.product_no} - ${product.product_name}`}
                          </Box>
                        )
                      })
                    )}
                  </Box>
                </Box>
              </Paper>
            </Popper>
          )}
        </>
      </ClickAwayListener>
    )
  }
)

const productSelectColumn = (
  options: ProductSelectOptions
): Column<Partial<Product> | null, ProductSelectOptions> => ({
  component: ProductSelectComponent,
  columnData: options,
  disableKeys: true,
  keepFocus: true,
  copyValue: (value) => null,
  pasteValue: (value) => null,
})

export default productSelectColumn
