import { useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'

import { MasterInputProps } from '../types'
import { ListQueryResult, Master } from '../../../../api/types'

import {
  Autocomplete,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useDebounce } from 'use-debounce'

export default function MasterInput({
  name,
  resource,
  fields,
}: MasterInputProps) {
  const { control, getFieldState } = useFormContext()

  const fieldState = getFieldState(name)

  const inputValue = useWatch({
    control,
    name,
    defaultValue: '',
  })

  const [selectedMaster, setSelectedMaster] = useState<Master | null>(null)

  const { data: master, refetch } = useQuery<Master>({
    queryKey: ['getOne', `effective-${resource}`, inputValue],
    enabled: !!inputValue && !fieldState.isDirty,
  })

  const [debouncedInputValue] = useDebounce(inputValue, 500)

  const { data: masterList } = useQuery<ListQueryResult<Master>>({
    queryKey: [
      'getList',
      `effective-${resource}`,
      { search: debouncedInputValue },
    ],
    enabled: !!debouncedInputValue && fieldState.isDirty,
  })

  return (
    <>
      {fields.map(({ name: fieldName, printName, entry, label }) => (
        <Controller
          key={fieldName}
          control={control}
          name={name}
          disabled={!entry}
          render={({
            field: { value, onChange, onBlur, disabled, ...fieldProps },
            fieldState,
          }) => (
            <Autocomplete
              {...fieldProps}
              freeSolo
              disabled={disabled}
              disableClearable
              options={masterList?.results ?? []}
              filterOptions={(options) => options}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option
                }
                return option?.code ?? ''
              }}
              renderOption={(props, option) => {
                if (typeof option === 'string') {
                  return <Typography {...props}>{option}</Typography>
                }
                const name = fields.map((field) => option[field.name]).join('/')
                return (
                  <Typography {...props}>
                    {`${option.code} (${name})`}
                  </Typography>
                )
              }}
              onChange={(e, newValue) => {
                setSelectedMaster(newValue)
              }}
              onBlur={() => {
                onBlur()
                if (!selectedMaster) {
                  refetch()
                }
              }}
              value={selectedMaster as any}
              inputValue={value}
              onInputChange={(e, value, reason) => {
                onChange(value)
                setSelectedMaster(null)
              }}
              renderInput={(params: any) => (
                <TextField
                  label={label}
                  fullWidth
                  {...params}
                  error={fieldState.error}
                  helperText={entry && fieldState.error?.message}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <Controller
                            control={control}
                            name={printName}
                            render={({ field: { value, ...fieldProps } }) => (
                              <Checkbox {...fieldProps} checked={value} />
                            )}
                          />
                        </InputAdornment>
                        {params.InputProps.startAdornment}
                      </>
                    ),
                    endAdornment: (
                      <>
                        <InputAdornment position="end">
                          <Typography color="info">
                            {selectedMaster?.[fieldName] ??
                              master?.[fieldName] ??
                              ''}
                          </Typography>
                        </InputAdornment>
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      ))}
    </>
  )
}
